import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New8({news8}) {
    useEffect(() => {
        document.title = news8.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'На G2E в Азии и Сингапуре самое время! Если вы здесь и хотите стать свидетелем краш-игры следующего поколения, не забудьте посетить наш стенд Aviatrix B1805.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news8.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news8.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news8.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>На G2E в Азии и Сингапуре самое время!</p>
            <p className='new__text'>Если вы здесь и хотите стать свидетелем краш-игры следующего поколения, не забудьте посетить наш стенд Aviatrix B1805.</p>
            <p className='new__text'>Поверьте нам, вы не будете разочарованы!</p>
            <p className='new__text'>Так что заходите, поговорите с нашей дружной командой, и давайте вместе сделаем эту выставку незабываемой!</p>
            <p className='new__text'>Кстати, у нас здесь есть переводчик, так что даже если мы не говорим на одном языке, мы обязательно поймем друг друга.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New8;

