import './Carousel.css';
import { useState } from 'react';
import {motion} from 'framer-motion';
const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,  
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }   
    }
}
export const Carousel = ({children}) => {
    const [offset, setOffset] = useState(0)
    const handleLeft = () => {
        setOffset((currenOffset) => {
            let newOffset = currenOffset + 330
            if (newOffset > 0) newOffset = -12559
            return newOffset
          })
    }
    const handleRight = () => {
       setOffset((currenOffset) => {
         let newOffset = currenOffset - 330
         if (newOffset < -12559) newOffset = 0
         return newOffset
       })
    }
    return (
        <motion.div 
        initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            variants={animation}
        className='news__container'>
        <div className='news__nav'>   
                <div className='news__arrow' onClick={handleLeft}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="1">
                <path d="M15.1953 9.52851C15.4557 9.26816 15.8778 9.26816 16.1381 9.52851C16.3985 9.78886 16.3985 10.211 16.1381 10.4713L12.6095 13.9999L16.1381 17.5285C16.3985 17.7889 16.3985 18.211 16.1381 18.4713C15.8778 18.7317 15.4557 18.7317 15.1953 18.4713L11.1953 14.4713C10.935 14.211 10.935 13.7889 11.1953 13.5285L15.1953 9.52851Z" fill="currentColor"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14ZM0.583334 14C0.583334 21.4098 6.59018 27.4167 14 27.4167C21.4098 27.4167 27.4167 21.4098 27.4167 14C27.4167 6.59018 21.4098 0.583333 14 0.583333C6.59018 0.583333 0.583334 6.59018 0.583334 14Z" fill="currentColor"></path>
                </g>
                </svg>
                </div>
                <div className='news__arrow'  onClick={handleRight}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="1">
                <path d="M12.8047 9.52851C12.5443 9.26816 12.1222 9.26816 11.8619 9.52851C11.6015 9.78886 11.6015 10.211 11.8619 10.4713L15.3905 13.9999L11.8619 17.5285C11.6015 17.7889 11.6015 18.211 11.8619 18.4713C12.1222 18.7317 12.5443 18.7317 12.8047 18.4713L16.8047 14.4713C17.065 14.211 17.065 13.7889 16.8047 13.5285L12.8047 9.52851Z" fill="currentColor"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM27.4167 14C27.4167 21.4098 21.4098 27.4167 14 27.4167C6.59018 27.4167 0.583333 21.4098 0.583333 14C0.583333 6.59018 6.59018 0.583333 14 0.583333C21.4098 0.583333 27.4167 6.59018 27.4167 14Z" fill="currentColor"></path>
                </g>
                </svg>
                </div>
        </div>
        <div className="news__window">
            <div className='news__cards'
            style={{transform: `translateX(${offset}px)`, transition: `all 0.5s ease 0.2s`}}
            >{children}</div>
        </div>
    </motion.div>
    )
}