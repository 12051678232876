import './Header.css';
import logo from '../../images/logo.svg';
import {Link} from 'react-router-dom';

function Header({setActive}) {
    return (
        <div className='header'>
            <Link to="/" className='header__logo'>
                <img className='header__logo-img' src={logo} alt='логотип'></img>
            </Link>
            <div className='header__items'>
                <a  data-section="a" className='header__item' href="/#Benefits">
                    <p className='header__menu'>Механика</p>
                </a>
                <a className='header__item' href="/#NFT">
                    <p className='header__menu'>NFT</p>
                </a>
                <a className='header__item' href="/#Rewards">
                    <p className='header__menu'>Награды</p>
                </a>
                <a className='header__item' href='/#Branding3'>
                    <p className='header__menu'>Брендинг</p>
                </a>
            </div>
            <div className='header__language'>
                <button className='header__button' onClick={() => setActive(true)}>Связаться</button>
            </div>
</div>
)}

export default Header;
