import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New18({news18}) {
    useEffect(() => {
        document.title = news18.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Авиатрикс взлетает в небо и с нетерпением ждет встречи с вами! Наша команда рада принять участие в одной из самых ожидаемых выставок iGaming года, где мы сможем изучить новые возможности для бизнеса.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news18.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news18.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news18.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Авиатрикс взлетает в небо и с нетерпением ждет встречи с вами! 🚀</p>
            <p className='new__text'>Наша команда рада принять участие в одной из самых ожидаемых выставок iGaming года, где мы сможем изучить новые возможности для бизнеса. Наша главная цель — наладить значимые связи с единомышленниками, которые разделяют нашу страсть к инновациям и раздвигают границы возможного в индустрии iGaming.</p>
            <p className='new__text'>Итак, где вы можете найти нас?</p>
            <p className='new__text'>Вот наше расписание на этот год:</p>
            <p className='new__text'>⭐️ SBC Summit North America NJ — США ⭐️ 09.05.2023-11.05.2023</p>
            <p className='new__text'>⭐️ CasinoBeats Malta — Мальта ⭐️ 23.05.2023-25.05.2023</p>
            <p className='new__text'>⭐️ G2E Asia — Сингапур ⭐️ 30.05.2023-01.06.2023</p>
            <p className='new__text'>⭐️ Sigma Brazil — Бразилия ⭐️ 14.06.23-18.06.23\⭐️</p>
            <p className='new__text'>⭐️ EGR Global B2B Awards 2023 — Великобритания ⭐️ 08.06.23</p>
            <p className='new__text'>⭐️ IGB — Нидерланды ⭐️ 11.07.2023-14.07.2023</p>
            <p className='new__text'>⭐️ Sigma Asia — Филиппины ⭐️ 19.07.2023-22.07.2023</p>
            <p className='new__text'>⭐️ Sigma Balkans and CIS — Кипр ⭐️ 04.09.2023-07.09.2023</p>
            <p className='new__text'>⭐️ Саммит SBC Барселона — Испания ⭐️ 19.09.2023-21.09.2023</p>
            <p className='new__text'>⭐️ Брифинг и награждение EGR Italy — Италия ⭐️ 19.10.2023</p>
            <p className='new__text'>⭐️ SBC СНГ — Грузия ⭐️ 23.10.2023-24.10.2023</p>
            <p className='new__text'>⭐️ Лондонский саммит EGR и награды операторов — Великобритания ⭐️ 25.10.2023-26.10.2023</p>
            <p className='new__text'>⭐️ SBC Summit Latinoamerica — США ⭐️ 31.10.2023-02.11.2023</p>
            <p className='new__text'>⭐️ Sigma Europe — Мальта ⭐️ 13.11.2023-17.11.2023</p>
            <div className='new__column'>
                <p className='new__text'>Возможно, мы идеально подходим для вашего бизнеса. Давайте объединяться, сотрудничать и создавать что-то действительно потрясающее вместе. Увидимся! 🤝</p>
            </div>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New18;

