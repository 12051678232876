import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New5({news5}) {
    useEffect(() => {
        document.title = news5.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Новый турнир для наших игроков "Получи деньги, пока не разорился!" в Авиатрикс стартовала! Двигатели набирают обороты, и самолеты рвутся в полет.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news5.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news5.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news5.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Новый турнир для наших игроков "Получи деньги, пока не разорился!" в Авиатрикс стартовала!</p>
            <p className='new__text'>Двигатели набирают обороты, и самолеты рвутся в полет.</p>
            <p className='new__text'>Нас ждет напряженное соревнование, в котором опытные пилоты сразятся за ошеломляющий призовой фонд в 600 000 евро!</p>
            <p className='new__text'>Да, вы не ослышались, действительно невероятное количество ждет достойных пилотов.</p>
            <p className='new__text'>Давайте дождемся впечатляющих результатов, которые принесет этот турнир!</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New5;

