import './Banner.css';
import {motion} from 'framer-motion';
import banner1 from '../../images/banner1.png';
import banner2 from '../../images/banner2.png';
import banner3 from '../../images/banner3.png';
import banner4 from '../../images/banner4.png';
const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,  
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function Banner() {
    return (
        <motion.section 
        initial='hidden'
        whileInView='visible'
        viewport={{ onse: true}}
        className='banner'>
            <img className='banner__moon' src={banner1} alt='луна' loading="lazy"></img>
            <img className='banner__minairplane' src={banner2} alt='самолет' loading="lazy"></img> 
            <img className='banner__airplane' src={banner3} alt='самолет' loading="lazy"></img> 
            <img className='banner__comet' src={banner4} alt='комета' loading="lazy"></img>  
           <motion.div viewport={{ once: true }} variants={animation} className='banner__block'>
            <h1 className='banner__title'>
                NFT на основе B2B<br></br>
                Игровое решение</h1>
        </motion.div>
        <motion.a variants={animation} viewport={{ onse: true}}  className='banner__button' href='/go' rel='nofollow'>
            <div className='banner__icon'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91667 6.39167C8.1745 6.24281 8.49217 6.24281 8.75 6.39167L13.75 9.27842C14.0078 9.42729 14.1667 9.70239 14.1667 10.0001C14.1667 10.2978 14.0078 10.5729 13.75 10.7218L8.75 13.6086C8.49217 13.7574 8.1745 13.7574 7.91667 13.6086C7.65883 13.4597 7.5 13.1846 7.5 12.8869V7.11336C7.5 6.81564 7.65883 6.54054 7.91667 6.39167Z" fill="currentColor"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M10 0.833252C4.9374 0.833252 0.833344 4.93731 0.833344 9.99992C0.833344 15.0625 4.9374 19.1666 10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252ZM2.50001 9.99992C2.50001 5.85778 5.85787 2.49992 10 2.49992C14.1421 2.49992 17.5 5.85778 17.5 9.99992C17.5 14.1421 14.1421 17.4999 10 17.4999C5.85787 17.4999 2.50001 14.1421 2.50001 9.99992Z" fill="currentColor"></path>
            </svg>
            </div>
            <p className='banner__paragraph'>Играть</p>
        </motion.a>
        </motion.section>
    )
}

export default Banner;
