import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New4({news4}) {
    useEffect(() => {
        document.title = news4.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Всего несколько дней осталось до вручения награды EGR Global B2B Awards 2023! Наша невероятная команда полна желания встретить всех присутствующих на церемонии награждения.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news4.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news4.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news4.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Обратный отсчет идет!</p>
            <p className='new__text'>Всего несколько дней осталось до вручения награды EGR Global B2B Awards 2023!</p>
            <p className='new__text'>Наша невероятная команда полна желания встретить всех присутствующих на церемонии награждения.</p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href="https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4">Николай Побал</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href="https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4">Анастасия Римская</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href="https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4">Люси Кадлецова</a></p>
            <p className='new__text'>Мы, затаив дыхание, скрещиваем пальцы для Aviatrix в двух категориях: восходящая звезда поставщика игровых автоматов и инновации в программном обеспечении казино RNG.</p>
            <p className='new__text'>Ожидание результатов зашкаливает.</p>
            <p className='new__text'>До встречи на церемонии награждения, и давайте отлично проведем время вместе!</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New4;

