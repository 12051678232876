import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New31({news31}) {
    useEffect(() => {
        document.title = news31.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Продолжаем следить за результатами наших новых и старых игроков Авиатрикс 🚀, и на этой неделе самого удачливого пилота зовут Мака. Мака выиграла 1940$ 💸 . Мы желаем Маке удачи во всех будущих полетах и призываем всех остальных попробовать нашу новую эксклюзивную игру NFT.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news31.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news31.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news31.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Продолжаем следить за результатами наших новых и старых игроков Авиатрикс 🚀, и на этой неделе самого удачливого пилота зовут Мака. Мака выиграла 1940$ 💸 . Мы желаем Маке удачи во всех будущих полетах 👏 и призываем всех остальных попробовать нашу новую эксклюзивную игру NFT.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New31;

