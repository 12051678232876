import './Features.css';
import {motion} from 'framer-motion';
import features1 from '../../images/features1.svg';
import features2 from '../../images/features2.svg';
import features3 from '../../images/features3.svg';

const animation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1, 
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }       
    }
}

function Features() {
    return (
        <section 
        className='features'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='features__container'>
            <motion.div variants={animation} className='features__label'>Мы служим вам</motion.div>
            <motion.div variants={animation} className='features__text'>
                <h2 className='features__title'>Ключевые Функции</h2>
            </motion.div>
            <motion.div variants={animation} className='features__cards'>
                <div className='features__card'>
                    <img className='features__img' loading="lazy" src={features1} alt='Механика'></img>
                    <div className='features__head'>Усовершенствованная Механика Сбоев</div>
                    <p className='features__subhead'>Тенденции на основе NFT, которые понравятся вашим пользователям</p>
                </div>
                <div className='features__card'>
                    <img className='features__img' loading="lazy" src={features2} alt='Настройка'></img>
                    <div className='features__head'>Настройка и Простая Интеграция</div>
                    <p className='features__subhead'>Глубокая настройка, которая сделает игру родной для вашего интерфейса</p>
                </div>
                <div className='features__card'>
                    <img className='features__img' loading="lazy" src={features3} alt='программа лояльности'></img>
                    <div className='features__head'>Механика Лояльности На Основе Взаимодействия</div>
                    <p className='features__subhead'>Уникальная программа лояльности, которая выводит взаимодействие на совершенно новый уровень</p>
                </div>
            </motion.div>
            </motion.div>
        </section>
    )
}

export default Features;
