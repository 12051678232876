import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New35({news35}) {
    useEffect(() => {
        document.title = news35.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Мы посетим SiGMA - Worlds Gaming Festival на Мальте с 14 по 18 ноября 2022 года и будем рады встретить там наших старых и новых партнеров на нашем стенде ST77 🏦. Наши коллеги Николай Побал, Виктория Ламброза и Олег Смолеров будут там , чтобы обсудить любые предстоящие вопросы по нашей игре Авиатрик . Пожалуйста, свяжитесь напрямую, чтобы назначить встречу. До скорой встречи!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news35.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news35.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news35.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Мы посетим <a target="_blank" className='new__link' href='https://www.linkedin.com/company/worldsgamingfestival/'>SiGMA - World's Gaming Festival</a>на Мальте с 14 по 18 ноября 2022 года и будем рады встретить там наших старых и новых партнеров на нашем стенде ST77 🏦. Наши коллеги <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4'>Николай Побал</a>, <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAABPpduUBIOXToBnwtshUf6xXuS2GGwa11Ms'>Анаит Петросян </a>, Виктория Ламброза и <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAACEUUmgBrTC-0blpm6hzdsewZMh7vLG2DR0'> Олег Смолеров</a>будут там , чтобы обсудить любые предстоящие вопросы по нашей игре Авиатрикс ✈ . Пожалуйста, свяжитесь напрямую, чтобы назначить встречу 📅. До скорой встречи! 🖐</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New35;

