import './Wrapper.css';
import wrapper from '../../images/wrapper.png';
import wrapper2 from '../../images/wrapper2.png';

function Wrapper() {
    return (
        <div className='wrapper'>
            <img className='wrapper__img' src={wrapper} alt='фон'></img>
            <img className='wrapper__img' src={wrapper2} alt='фон'></img>
        </div>
    )
}

export default Wrapper;
