import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New42({news42}) {
    useEffect(() => {
        document.title = news42.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Quantum Gaming, поставщик высококлассных платформ, рада объявить о недавно установленном партнерстве с гиперсовременным провайдером iGaming, Aviatrix. Как следует из названия, Aviatrix поднимет операторов высоко в небо с захватывающей и интригующей игровой моделью астрономического роста — Play To Earn (P2E). Это абсолютно новый игровой опыт, предлагающий множество преимуществ как для операторов, так и для игроков.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news42.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news42.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news42.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Quantum Gaming, поставщик высококлассных платформ, рада объявить о недавно установленном партнерстве с гиперсовременным провайдером iGaming, Aviatrix.</p>
            <p className='new__text'>Как следует из названия, Aviatrix поднимет операторов высоко в небо с захватывающей и интригующей игровой моделью астрономического роста — Play To Earn (P2E). Это абсолютно новый игровой опыт, предлагающий множество преимуществ как для операторов, так и для игроков.</p>
            <p className='new__text'>Aviatrix выходит далеко за рамки простого развлечения. С его краш-игрой P2E на базе NFT у игроков есть уникальная возможность заработать деньги самым увлекательным и захватывающим способом и получить вознаграждение с реальной ценностью. От создания игрового объекта до получения призов за активную игру геймеры смогут создавать настоящую ценность, которая выводит вовлеченность на совершенно новый уровень.</p>
            <p className='new__text'>Используя эту игровую концепцию следующего поколения на основе блокчейна, которую легко интегрировать и полностью настраивать, операторы не только обогатят свои предложения, но и заручатся лояльностью заядлых онлайн-геймеров, желающих извлечь выгоду из этой тенденции.</p>
            <p className='new__text'>Aviatrix, несомненно, прилагает усилия к качеству и созданию игрового процесса с уникальными механиками, позволяющими им выделяться из толпы и достигать лучших профилей игроков.</p>
            <p className='new__text'>«Несмотря на то, что игры, основанные на принципах «играй, чтобы заработать», все еще находятся на ранней стадии, они приобрели большую популярность. Я уверен, что она станет любимой игровой моделью, от которой люди будут получать удовольствие и получать прибыль. Вот почему Aviatrix с ее захватывающим и актуальным контентом, безусловно, является изысканным дополнением к нашей платформе iGaming», — сказал Оливер де Боно, генеральный директор Quantum Gaming.</p>
            <p className='new__text'>«Aviatrix — это iGaming-студия, специализирующаяся на инновационных продуктах в самой трендовой нише игрового провайдера — «аварийной механике». Мы объединяем самые современные технологии, такие как блокчейн, NFT и AI, в одном продукте. Сочетание всех этих технологий и то, как они дополняют друг друга, позволяет нам создать решение, которое имеет большой потенциал для изменения стандартов индустрии iGaming», — добавил Владислав Артемьев, генеральный директор Aviatrix.</p>
            <p className='new__text'>Игры, в которых нужно зарабатывать, уже давно стали предметом разговоров в городе. Перейдите на страницу «Свяжитесь с нами» или посетите наш профиль в LinkedIn, если вы хотите узнать, о чем идет речь.</p>
            <p className='new__text'>Полная версия <a target="_blank" className='new__link' href='https://europeangaming.eu/portal/latest-news/2022/10/13/122854/quantum-gaming-is-expanding-its-game-offering-with-trendy-nft-based-content/'>здесь</a></p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New42;

