import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New38({news38}) {
    useEffect(() => {
        document.title = news38.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'У нас отличные новости 📣. Наша игра Aviatrix вошла в шорт-лист как ЛУЧШАЯ CRASH GAME нa SiGMA - Worlds Gaming Festival Sigma Awards Europe 2022.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news38.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news38.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news38.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>У нас отличные новости 📣. Наша игра Aviatrix вошла в шорт-лист как ЛУЧШАЯ CRASH GAME на <a target="_blank" className='new__link' href='https://www.linkedin.com/company/worldsgamingfestival/'>SiGMA - World's Gaming Festival</a> Sigma Awards Europe 2022 😀. Поддержите нас, проголосовав за нашу эксклюзивную игру NFT Crash здесь:</p>
            <p className='new__text'><a target="_blank" className='new__link' href='https://lnkd.in/gjTgXPTE'></a>https://lnkd.in/gjTgXPTE</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New38;

