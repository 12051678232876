import './News.css';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import news from '../../images/news.png';
import {Carousel} from '../../carousel/Carousel.js';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1, 
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }    
    }
}

function News({news1, news2, news3, news4, news5, news6, news7, news8, news9, news10, news11, news12, news13, news14, news15, news16, news17, news18, news19, news20, news21, news22, news23, news24, news25, news26, news27, news28, news29, news30, news31, news32, news33, news34, news35, news36, news37, news38, news39, news40, news41, news42,}) {
    return (
        <section className='news'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='news__row'>
                <motion.div variants={animation} className='news__label'>Новости</motion.div>
                <motion.h2 variants={animation} className='news__title'>Самые Интересные Новости<br></br>и События</motion.h2>
            </motion.div>
            <Carousel>
        <div className='news__card'>
                <Link  to="/news/aviatrix-review-at-gamblescope" className='news__link'>
                    <img className='news__img' src={news1.image} alt='новость' loading='lazy'></img>
                </Link>
                 <Link to='/news/aviatrix-review-at-gamblescope' className='news__link' >
                    <div className='news__data'>{news1.data}</div>
                </Link>
                  <Link to='/news/aviatrix-review-at-gamblescope' className='news__link'>
                    <div className='news__head'>{news1.text}</div>
                </Link>
            </div>
            <div className='news__card'>
                  <Link to='/news/aviatrix-review-at-list-casino' className='news__link'>
                    <img className='news__img' src={news2.image} alt='новость' loading='lazy'></img>
                </Link>
                 <Link to='/news/aviatrix-review-at-list-casino' className='news__link'  >
                    <div className='news__data'>{news2.data}</div>
                </Link>
                <Link to='/news/aviatrix-review-at-list-casino' className='news__link'  >
                    <div className='news__head'>{news2.text}</div>
                </Link>
            </div>
            <div className='news__card'>
                <Link to='/news/aviatrix-at-brazilian-igaming-summit' className='news__link'>
                    <img className='news__img' src={news3.image} alt='новость' loading='lazy'></img>
                </Link>
                <Link to='/news/aviatrix-at-brazilian-igaming-summit' className='news__link'  >
                    <div className='news__data'>{news3.data}</div>
                </Link>
                <Link to='/news/aviatrix-at-brazilian-igaming-summit' className='news__link'  >
                    <div className='news__head'>{news3.text}</div>
                </Link>
            </div>
            <div className='news__card'>
                <Link to='/news/heading-to-b2b-egr-awards' className='news__link'>
                    <img className='news__img' src={news4.image} alt='новость' loading='lazy'></img>
                </Link> 
                <Link to='/news/heading-to-b2b-egr-awards' className='news__link'>
                    <div className='news__data'>{news4.data}</div>
                </Link>
                <Link to='/news/heading-to-b2b-egr-awards' className='news__link'>
                    <div className='news__head'>{news4.text}</div>
                </Link>
            </div>
            <div className='news__card'>
                <Link to='/news/get-cash-before-you-crash-tournament' className='news__link'>
                    <img className='news__img' src={news5.image} alt='новость' loading='lazy'></img>
                </Link>
                <Link to='/news/get-cash-before-you-crash-tournament' className='news__link'>
                    <div className='news__data'>{news5.data}</div>
                </Link>
                <Link to='/news/get-cash-before-you-crash-tournament' className='news__link'>
                    <div className='news__head'>{news5.text}</div>
                </Link>
            </div>
            <div className='news__card'>
                        <Link  to='/news/3rd-day-at-g2e-in-singapore' className='news__link'  >
                            <img className='news__img' src={news6.image} alt='новость' loading='lazy'></img>
                        </Link>
                        <Link to='/news/3rd-day-at-g2e-in-singapore' className='news__link'>
                            <div className='news__data'>{news6.data}</div>
                        </Link>
                        <Link to='/news/3rd-day-at-g2e-in-singapore' className='news__link'>
                            <div className='news__head'>{news6.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                        <Link to='/news/aviatrix-at-g2e-in-asia' className='news__link'>
                            <img className='news__img' src={news7.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-at-g2e-in-asia' className='news__link'  >
                            <div className='news__data'>{news7.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-at-g2e-in-asia' className='news__link'  >
                            <div className='news__head'>{news7.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-at-g2e-in-singapore-day-1' className='news__link'>
                            <img className='news__img' src={news8.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-at-g2e-in-singapore-day-1' className='news__link'>
                            <div className='news__data'>{news8.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-at-g2e-in-singapore-day-1' className='news__link'>
                            <div className='news__head'>{news8.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/our-team-in-singapore' className='news__link'>
                            <img className='news__img' src={news9.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/our-team-in-singapore' className='news__link' >
                            <div className='news__data'>{news9.data}</div>
                        </Link>
                         <Link to='/news/our-team-in-singapore' className='news__link'>
                            <div className='news__head'>{news9.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/final-day-of-casinobeats-game-developers-awards' className='news__link'>
                            <img className='news__img' src={news10.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/final-day-of-casinobeats-game-developers-awards' className='news__link'>
                            <div className='news__data'>{news10.data}</div>
                        </Link>
                         <Link to='/news/final-day-of-casinobeats-game-developers-awards' className='news__link' >
                            <div className='news__head'>{news10.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/nft-project-of-the-year-nomination' className='news__link'>
                            <img className='news__img' src={news11.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/nft-project-of-the-year-nomination' className='news__link'>
                            <div className='news__data'>{news11.data}</div>
                        </Link>
                         <Link to='/news/nft-project-of-the-year-nomination' className='news__link'>
                            <div className='news__head'>{news11.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/sbc-summit-north-america-roundup' className='news__link'>
                            <img className='news__img' src={news12.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/sbc-summit-north-america-roundup' className='news__link'>
                            <div className='news__data'>{news12.data}</div>
                        </Link>
                         <Link to='/news/sbc-summit-north-america-roundup' className='news__link' >
                            <div className='news__head'>{news12.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-shortlisted-at-egr-marketing-innovation-awards-2023' className='news__link'>
                            <img className='news__img' src={news13.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-shortlisted-at-egr-marketing-innovation-awards-2023' className='news__link'>
                            <div className='news__data'>{news13.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-shortlisted-at-egr-marketing-innovation-awards-2023' className='news__link'>
                            <div className='news__head'>{news13.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-is-teaming-up-with-slotpesa-casino' className='news__link'>
                            <img className='news__img' src={news14.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-is-teaming-up-with-slotpesa-casino' className='news__link'>
                            <div className='news__data'>{news14.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-is-teaming-up-with-slotpesa-casino' className='news__link'>
                            <div className='news__head'>{news14.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/day-1-at-sbc-summit-north-america' className='news__link'>
                            <img className='news__img' src={news15.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/day-1-at-sbc-summit-north-america' className='news__link'>
                            <div className='news__data'>{news15.data}</div>
                        </Link>
                         <Link to='/news/day-1-at-sbc-summit-north-america' className='news__link'>
                            <div className='news__head'>{news15.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/igathering-event-sponsorship' className='news__link'>
                            <img className='news__img' src={news16.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/igathering-event-sponsorship' className='news__link'>
                            <div className='news__data'>{news16.data}</div>
                        </Link>
                         <Link to='/news/igathering-event-sponsorship' className='news__link'>
                            <div className='news__head'>{news16.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-shortlisted-for-game-innovation-spotlight-award' className='news__link'>
                            <img className='news__img' src={news17.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-shortlisted-for-game-innovation-spotlight-award' className='news__link'>
                            <div className='news__data'>{news17.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-shortlisted-for-game-innovation-spotlight-award' className='news__link'>
                            <div className='news__head'>{news17.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/schedule-of-events-for-2023' className='news__link'>
                            <img className='news__img' src={news18.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/schedule-of-events-for-2023' className='news__link'>
                            <div className='news__data'>{news18.data}</div>
                        </Link>
                         <Link to='/news/schedule-of-events-for-2023' className='news__link'>
                            <div className='news__head'>{news18.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/interview-with-gamblerspick' className='news__link'>
                            <img className='news__img' src={news19.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/interview-with-gamblerspick' className='news__link'>
                            <div className='news__data'>{news19.data}</div>
                        </Link>
                         <Link to='/news/interview-with-gamblerspick' className='news__link'>
                            <div className='news__head'>{news19.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/entering-into-the-new-year-with-the-eu100-000-tournament-from-aviatrix' className='news__link'>
                            <img className='news__img' src={news20.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/entering-into-the-new-year-with-the-eu100-000-tournament-from-aviatrix' className='news__link'>
                            <div className='news__data'>{news20.data}</div>
                        </Link>
                         <Link to='/news/entering-into-the-new-year-with-the-eu100-000-tournament-from-aviatrix' className='news__link'>
                            <div className='news__head'>{news20.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/we-have-integrated-aviatrix-to-mostbet' className='news__link'>
                            <img className='news__img' src={news21.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/we-have-integrated-aviatrix-to-mostbet' className='news__link'>
                            <div className='news__data'>{news21.data}</div>
                        </Link>
                         <Link to='/news/we-have-integrated-aviatrix-to-mostbet' className='news__link'>
                            <div className='news__head'>{news21.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/merry-christmas' className='news__link'>
                            <img className='news__img' src={news22.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/merry-christmas' className='news__link'>
                            <div className='news__data'>{news22.data}</div>
                        </Link>
                         <Link to='/news/merry-christmas' className='news__link'>
                            <div className='news__head'>{news22.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/new-partner-gamingtec' className='news__link'>
                            <img className='news__img' src={news23.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/new-partner-gamingtec' className='news__link'>
                            <div className='news__data'>{news23.data}</div>
                        </Link>
                         <Link to='/news/new-partner-gamingtec' className='news__link'>
                            <div className='news__head'>{news23.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-at-the-international-gaming-awards' className='news__link'>
                            <img className='news__img' src={news24.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-at-the-international-gaming-awards' className='news__link'>
                            <div className='news__data'>{news24.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-at-the-international-gaming-awards' className='news__link'>
                            <div className='news__head'>{news24.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/new-operator-vbet' className='news__link'>
                            <img className='news__img' src={news25.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/new-operator-vbet' className='news__link'>
                            <div className='news__data'>{news25.data}</div>
                        </Link>
                         <Link to='/news/new-operator-vbet' className='news__link'>
                            <div className='news__head'>{news25.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/eu25-000-new-years-tournament-in-aviatrix-i' className='news__link'>
                            <img className='news__img' src={news26.image} alt='новость' loading='lazy'></img>
                        </Link>
                          <Link to='/news/eu25-000-new-years-tournament-in-aviatrix-i' className='news__link'>
                            <div className='news__data'>{news26.data}</div>
                        </Link>
                          <Link to='/news/eu25-000-new-years-tournament-in-aviatrix-i' className='news__link'>
                            <div className='news__head'>{news26.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/favbet-one-more-casino-to-play-aviatrix-at' className='news__link'>
                            <img className='news__img' src={news27.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/favbet-one-more-casino-to-play-aviatrix-at' className='news__link'>
                            <div className='news__data'>{news27.data}</div>
                        </Link>
                         <Link to='/news/favbet-one-more-casino-to-play-aviatrix-at' className='news__link'>
                            <div className='news__head'>{news27.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/interview-with-crypto-gambling-news' className='news__link'>
                            <img className='news__img' src={news28.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/interview-with-crypto-gambling-news' className='news__link'>
                            <div className='news__data'>{news28.data}</div>
                        </Link>
                         <Link to='/news/interview-with-crypto-gambling-news' className='news__link'>
                            <div className='news__head'>{news28.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/live-at-parimatch' className='news__link'>
                            <img className='news__img' src={news29.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/live-at-parimatch' className='news__link'>
                            <div className='news__data'>{news29.data}</div>
                        </Link>
                         <Link to='/news/live-at-parimatch' className='news__link'>
                            <div className='news__head'>{news29.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/first-ever-aviatrix-network-tournament-is-live' className='news__link'>
                            <img className='news__img' src={news30.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/first-ever-aviatrix-network-tournament-is-live' className='news__link'>
                            <div className='news__data'>{news30.data}</div>
                        </Link>
                         <Link to='/news/first-ever-aviatrix-network-tournament-is-live' className='news__link'>
                            <div className='news__head'>{news30.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/congratulations-to-the-winner' className='news__link'>
                            <img className='news__img' src={news31.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/congratulations-to-the-winner' className='news__link'>
                            <div className='news__data'>{news31.data}</div>
                        </Link>
                         <Link to='/news/congratulations-to-the-winner' className='news__link'>
                            <div className='news__head'>{news31.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/new-collaboration-with-leon-bet' className='news__link'>
                            <img className='news__img' src={news32.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/new-collaboration-with-leon-bet' className='news__link'>
                            <div className='news__data'>{news32.data}</div>
                        </Link>
                         <Link to='/news/new-collaboration-with-leon-bet' className='news__link'>
                            <div className='news__head'>{news32.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/sigma-worlds-gaming-festival-wrap-up' className='news__link'>
                            <img className='news__img' src={news33.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/sigma-worlds-gaming-festival-wrap-up' className='news__link'>
                            <div className='news__data'>{news33.data}</div>
                        </Link>
                         <Link to='/news/sigma-worlds-gaming-festival-wrap-up' className='news__link'>
                            <div className='news__head'>{news33.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/1st-day-at-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <img className='news__img' src={news34.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/1st-day-at-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <div className='news__data'>{news34.data}</div>
                        </Link>
                         <Link to='/news/1st-day-at-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <div className='news__head'>{news34.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/we-are-planning-to-visit-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <img className='news__img' src={news35.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/we-are-planning-to-visit-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <div className='news__data'>{news35.data}</div>
                        </Link>
                         <Link to='/news/we-are-planning-to-visit-sigma-worlds-gaming-festival-in-malta' className='news__link'>
                            <div className='news__head'>{news35.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/an-exciting-and-promising-cooperation' className='news__link'>
                            <img className='news__img' src={news36.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/an-exciting-and-promising-cooperation' className='news__link'>
                            <div className='news__data'>{news36.data}</div>
                        </Link>
                         <Link to='/news/an-exciting-and-promising-cooperation' className='news__link'>
                            <div className='news__head'>{news36.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/europeangaming-on-aviatrix' className='news__link'>
                            <img className='news__img' src={news37.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/europeangaming-on-aviatrix' className='news__link'>
                            <div className='news__data'>{news37.data}</div>
                        </Link>
                         <Link to='/news/europeangaming-on-aviatrix' className='news__link'>
                            <div className='news__head'>{news37.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/vote-for-aviatrix' className='news__link'>
                            <img className='news__img' src={news38.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/vote-for-aviatrix' className='news__link'>
                            <div className='news__data'>{news38.data}</div>
                        </Link>
                         <Link to='/news/vote-for-aviatrix' className='news__link'>
                            <div className='news__head'>{news38.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/collaboration-with-everymatrix' className='news__link'>
                            <img className='news__img' src={news39.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/collaboration-with-everymatrix' className='news__link'>
                            <div className='news__data'>{news39.data}</div>
                        </Link>
                         <Link to='/news/collaboration-with-everymatrix' className='news__link'>
                            <div className='news__head'>{news39.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/the-release-of-aviatrix' className='news__link'>
                            <img className='news__img' src={news40.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/the-release-of-aviatrix' className='news__link'>
                            <div className='news__data'>{news40.data}</div>
                        </Link>
                         <Link to='/news/the-release-of-aviatrix' className='news__link'>
                            <div className='news__head'>{news40.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/aviatrix-partners-with-betconstruct' className='news__link'>
                            <img className='news__img' src={news41.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/aviatrix-partners-with-betconstruct' className='news__link'>
                            <div className='news__data'>{news41.data}</div>
                        </Link>
                         <Link to='/news/aviatrix-partners-with-betconstruct' className='news__link'>
                            <div className='news__head'>{news41.text}</div>
                        </Link>
                    </div>
                    <div className='news__card'>
                         <Link to='/news/game-offering-with-trendy-nft-based-content' className='news__link'>
                            <img className='news__img' src={news42.image} alt='новость' loading='lazy'></img>
                        </Link>
                         <Link to='/news/game-offering-with-trendy-nft-based-content' className='news__link'>
                            <div className='news__data'>{news42.data}</div>
                        </Link>
                        <Link to='/news/game-offering-with-trendy-nft-based-content' className='news__link'>
                            <div className='news__head'>{news42.text}</div>
                        </Link>
                    </div>
        </Carousel>
            <img className='news__moon' src={news} alt='луна' loading='lazy'></img>
        </section>
    )
}

export default News;
