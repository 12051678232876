import './Planes.css';
import {motion} from 'framer-motion';
import luna from '../../images/luna.png';
import star from '../../images/star.svg';
import planes1 from '../../images/planes1.png';
import planes2 from '../../images/planes2.png';
import planes3 from '../../images/planes3.png';
import planes4 from '../../images/planes4.png';
import planes5 from '../../images/planes5.png';
import planes6 from '../../images/planes6.png';
import planes7 from '../../images/planes7.png';
import planes8 from '../../images/planes8.png';
import planes9 from '../../images/planes9.png';
import planes10 from '../../images/planes10.png';
import planes11 from '../../images/planes11.png';
import planes12 from '../../images/planes12.png';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,  
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }   
    }
}

function Planes() {
    return (
        <section id="NFT" className='planes'>
            <img className='planes__moon' src={luna} alt='луна' loading='lazy'></img>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='planes__row'>
                <div className='planes__column'>
                    <motion.div variants={animation} className='planes__label'>Дыхание будущего</motion.div>
                    <motion.h2 variants={animation} className='planes__title'>Сладкий Бонус для Тех, Кто Знаком с NFT</motion.h2>
                </div>
                <div className='planes__column'>
                    <motion.p variants={animation} className='planes__text'>Игроки соревнуются друг с другом, используя свои собственные самолеты, которые фактически являются NFT. Его можно настраивать, улучшать и участвовать в распределении вознаграждений.</motion.p>
                    <motion.div variants={animation} className='planes__container'>
                        <img className='planes__star' src={star} alt='звезда' loading='lazy'></img>
                        <p className='planes__paragraph'>Интеграция NFT не мешает игрокам, которые не заинтересованы.</p>
                    </motion.div>
                </div>
            </motion.div>
            <div className='planes__content'>
                <div className='planes__cards'>
                <div className='planes__card'>
                    <img className='planes__img' src={planes1} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>SkyFlier</div>
                    <p className='planes__subhead'>Уровень 3 · 414 XP</p>
                </div>
                <div  className='planes__card'>
                    <img className='planes__img' src={planes2} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>FastCucumber</div>
                    <p className='planes__subhead'>Уровень 2 · 34 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes3} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Stonehenge</div>
                    <p className='planes__subhead'>Уровень 1 · 7 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes4} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Lemonade</div>
                    <p className='planes__subhead'>Уровень 1 · 5 XP</p>
                </div>
                </div>
                <div className='planes__cards'>
                <div className='planes__card'>
                    <img className='planes__img' src={planes5} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>RedSun</div>
                    <p className='planes__subhead'>Уровень 8 · 1353 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes6} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>FireJet</div>
                    <p className='planes__subhead'>Уровень 1 · 8 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes7} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Milky</div>
                    <p className='planes__subhead'>Уровень 5 · 14 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes8} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>BlueMotion</div>
                    <p className='planes__subhead'>Уровень 6 · 5451 XP</p>
                </div>
                </div>
                <div className='planes__cards'>
                <div className='planes__card'>
                    <img className='planes__img' src={planes9} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Brownfury</div>
                    <p className='planes__subhead'>Уровень 2 · 69 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes10} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Chocofly</div>
                    <p className='planes__subhead'>Уровень 4 · 4823 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes11} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>Cloudlet</div>
                    <p className='planes__subhead'>Уровень 8 · 245 XP</p>
                </div>
                <div className='planes__card'>
                    <img className='planes__img' src={planes12} alt='самолет' loading='lazy'></img>
                    <div className='planes__head'>InvisibleFly</div>
                    <p className='planes__subhead'>Уровень 5 · 62345 XP</p>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Planes;
