import './App.css';
import { Route, Routes } from "react-router-dom";
import Main from '../Main/Main'; 
import Header from '../Header/Header';
import Clients from '../Clients/Clients';
import Popup from '../Popup/Popup';
import PageNotFound from '../PageNotFound/PageNotFound';
import New from '../New/New';
import New2 from '../New2/New2';
import New3 from '../New3/New3';
import New4 from '../New4/New4';
import New5 from '../New5/New5';
import New6 from '../New6/New6';
import New7 from '../New7/New7';
import New8 from '../New8/New8';
import New9 from '../New9/New9';
import New10 from '../New10/New10';
import New11 from '../New11/New11';
import New12 from '../New12/New12';
import New13 from '../New13/New13';
import New14 from '../New14/New14';
import New15 from '../New15/New15';
import New16 from '../New16/New16';
import New17 from '../New17/New17';
import New18 from '../New18/New18';
import New19 from '../New19/New19';
import New20 from '../New20/New20';
import New21 from '../New21/New21';
import New22 from '../New22/New22';
import New23 from '../New23/New23';
import New24 from '../New24/New24';
import New25 from '../New25/New25';
import New26 from '../New26/New26';
import New27 from '../New27/New27';
import New28 from '../New28/New28';
import New29 from '../New29/New29';
import New30 from '../New30/New30';
import New31 from '../New31/New31';
import New32 from '../New32/New32';
import New33 from '../New33/New33';
import New34 from '../New34/New34';
import New35 from '../New35/New35';
import New36 from '../New36/New36';
import New37 from '../New37/New37';
import New38 from '../New38/New38';
import New39 from '../New39/New39';
import New40 from '../New40/New40';
import New41 from '../New41/New41';
import New42 from '../New42/New42';
import slide1 from '../../images/slide1.jpg';
import slide2 from '../../images/slide2.jpg';
import slide3 from '../../images/slide3.jpg';
import slide4 from '../../images/slide4.jpg';
import slide5 from '../../images/slide5.jpg';
import slide6 from '../../images/slide6.jpg';
import slide7 from '../../images/slide7.jpg';
import slide8 from '../../images/slide8.jpg';
import slide9 from '../../images/slide9.jpg';
import slide10 from '../../images/slide10.jpg';
import slide11 from '../../images/slide11.jpg';
import slide12 from '../../images/slide12.jpg';
import slide13 from '../../images/slide13.jpg';
import slide15 from '../../images/slide15.jpg';
import slide16 from '../../images/slide16.jpg';
import slide17 from '../../images/slide17.jpg';
import slide18 from '../../images/slide18.jpg';
import slide19 from '../../images/slide19.jpg';
import slide20 from '../../images/slide20.jpg';
import slide21 from '../../images/slide21.jpg';
import slide22 from '../../images/slide22.jpg';
import slide23 from '../../images/slide23.jpg';
import slide24 from '../../images/slide24.jpg';
import slide25 from '../../images/slide25.jpg';
import slide26 from '../../images/slide26.jpg';
import slide27 from '../../images/slide27.jpg';
import slide28 from '../../images/slide28.jpg';
import slide29 from '../../images/slide29.jpg';
import slide30 from '../../images/slide30.jpg';
import slide31 from '../../images/slide31.jpg';
import slide32 from '../../images/slide32.jpg';
import slide33 from '../../images/slide33.jpg';
import slide34 from '../../images/slide34.png';
import slide35 from '../../images/slide35.png';
import slide36 from '../../images/slide36.jpg';
import slide37 from '../../images/slide37.jpg';
import slide38 from '../../images/slide38.jpg';
import slide39 from '../../images/slide39.jpg';
import slide40 from '../../images/slide40.jpg';
import slide41 from '../../images/slide41.jpg';
import slide42 from '../../images/slide42.jpg';
import slide43 from '../../images/slide43.jpg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  let news1 = {
    image: slide2,
    data: '7 июня 2023 г.',
    text: 'Обзор Aviatrix на Gamblescope'
}
 let news2 = {
    image: slide3,
    data: '7 июня 2023 г.',
    text: 'Обзор Авиатрикс в Казино'
}
let news3 = {
    image: slide4,
    data: '6 июня 2023 г.',
    text: 'Aviatrix на бразильском саммите Igaming'
}
let news4 = {
    image: slide1,
    data: '5 июня 2023 г.',
    text: 'На пути к B2B EGR Awards'
}
let news5 = {
    image: slide5,
    data: '2 июня 2023 г.',
    text: '«Получи наличные, прежде чем ты разобьешься!» турнир'
}
let news6 = {
    image: slide6,
    data: '2 июня 2023 г.',
    text: '3-й день на G2E в Сингапуре'
}
let news7 = {
    image: slide7,
    data: '31 мая 2023 г.',
    text: 'Aviatrix на G2E в Азии'
}
let news8 = {
    image: slide8,
    data: '30 мая 2023 г.',
    text: 'Aviatrix на G2E в Сингапуре - День 1'
}
let news9 = {
    image: slide9,
    data: '29 мая 2023 г.',
    text: 'Наша команда в Сингапуре'
}
let news10 = {
    image: slide10,
    data: '25 мая 2023 г.',
    text: 'Заключительный день церемонии награждения разработчиков игр Casinobeats'
}
let news11 = {
    image: slide11,
    data: '22 мая 2023 г.',
    text: 'Номинация NFT «Проект года»!'
}
let news12 = {
    image: slide12,
    data: '19 мая 2023 г.',
    text: 'Обзор саммита SBC в Северной Америке'
}
let news13 = {
    image: slide13,
    data: '17 мая 2023 г.',
    text: 'Aviatrix вошла в шорт-лист EGR Marketing & Innovation Awards 2023!'
}
let news14 = {
    image: slide15,
    data: '15 мая 2023 г.',
    text: 'Aviatrix объединяется с казино SlotPesa!'
}
let news15 = {
    image: slide16,
    data: '11 мая 2023 г.',
    text: 'День 1 на саммите SBC в Северной Америке'
}
let news16 = {
    image: slide17,
    data: '8 мая 2023 г.',
    text: 'Спонсорство мероприятия IGathering'
}
let news17 = {
    image: slide18,
    data: '1 мая 2023 г.',
    text: 'Aviatrix вошла в шорт-лист премии Game Innovation Spotlight Award'
}
let news18 = {
    image: slide19,
    data: '5 апреля 2023 г.',
    text: 'Расписание мероприятий на 2023 год'
}
let news19 = {
    image: slide20,
    data: '5 января 2023 г.',
    text: 'Интервью с Gamblerspick'
}
let news20 = {
    image: slide21,
    data: '1 января 2023 г.',
    text: 'Вступаем в Новый год с турниром на €100 000 от Aviatrix!'
}
let news21 = {
    image: slide22,
    data: '27 декабря 2022 г.',
    text: 'Мы интегрировали Aviatrix в Мостбет!'
}
let news22 = {
    image: slide23,
    data: '25 декабря 2022 г.',
    text: 'С Рождеством!'
}
let news23 = {
    image: slide24,
    data: '21 декабря 2022 г.',
    text: 'Новый партнер Gamingtec'
}
let news24 = {
    image: slide25,
    data: '19 декабря 2022 г.',
    text: 'Aviatrix на The International Gaming Awards!'
}
let news25 = {
    image: slide26,
    data: '15 декабря 2022 г.',
    text: 'Новый оператор VBET'
}
let news26 = {
    image: slide27,
    data: '14 декабря 2022 г.',
    text: '€25 000 Новогодний турнир в Aviatrix!'
}
let news27 = {
    image: slide28,
    data: '12 декабря 2022 г.',
    text: 'Favbet - Еще одно казино, где можно сыграть в Aviatrix 🚀'
}
let news28 = {
    image: slide29,
    data: '7 декабря 2022 г.',
    text: 'Интервью с Crypto Gambling News🚀'
}
let news29 = {
    image: slide30,
    data: '5 декабря 2022 г.',
    text: 'Прямой эфир на Parimatch🎺'
}
let news30 = {
    image: slide31,
    data: '2 декабря 2022 г.',
    text: 'Первый сетевой турнир Aviatrix уже в прямом эфире 🏆!'
}
let news31 = {
    image: slide32,
    data: '29 ноября 2022 г.',
    text: 'Поздравляем победителей 🏆!'
}
let news32 = {
    image: slide33,
    data: '23 ноября 2022 г.',
    text: 'Новое сотрудничество с Leon.bet'
}
let news33 = {
    image: slide34,
    data: '18 ноября 2022 г.',
    text: 'SiGMA — Всемирный игровой фестиваль. Заворачивать. ✌'
}
let news34 = {
    image: slide35,
    data: '15 ноября 2022 г.',
    text: '1-й день SiGMA - World\'s Gaming Festival на Мальте '
}
let news35 = {
    image: slide36,
    data: '2 ноября 2022 г.',
    text: 'Планируем посетить SiGMA - Всемирный игровой фестиваль на Мальте! 😀'
}
let news36 = {
    image: slide37,
    data: '29 октября 2022 г.',
    text: 'Интересное и перспективное сотрудничество!'
}
let news37 = {
    image: slide38,
    data: '28 октября 2022 г.',
    text: 'EuropeanGaming на Aviatrix'
}
let news38 = {
    image: slide39,
    data: '24 октября 2022 г.',
    text: 'Голосуйте за Aviatrix!'
}
let news39 = {
    image: slide40,
    data: '21 октября 2022 г.',
    text: 'Сотрудничество с EveryMatrix'
}
let news40 = {
    image: slide41,
    data: '19 октября 2022 г.',
    text: 'Выход Aviatrix'
}
let news41 = {
    image: slide42,
    data: '18 октября 2022 г.',
    text: 'Aviatrix сотрудничает с BetConstruct'
}
let news42 = {
    image: slide43,
    data: '13 октября 2022 г.',
    text: 'Предложение игр с модным контентом на основе NFT'
}
  const [modalActive, setModalActive] = useState(false);

  
  return (
    <div className="App">
          <Header setActive={setModalActive} /> 
   <Routes>
    <Route path="/" element={ 
    <Main news1={news1} news2={news2} news3={news3} news4={news4} news5={news5} news6={news6} news7={news7} news8={news8} news9={news9} news10={news10} news11={news11} news12={news12} news13={news13} news14={news14} news15={news15} news16={news16} news17={news17} news18={news18} news19={news19} news20={news20} news21={news21} news22={news22} news23={news23} news24={news24} news25={news25} news26={news26} news27={news27} news28={news28} news29={news29} news30={news30} news31={news31} news32={news32} news33={news33} news34={news34} news35={news35} news36={news36} news37={news37} news38={news38} news39={news39} news40={news40} news41={news41} news42={news42} active={modalActive} setActive={setModalActive}/>}>
     </Route>
     <Route path='/clients' element={<Clients/>}> 
     </Route>
     <Route path='/news/aviatrix-review-at-gamblescope' element={<New news1={news1}/>}></Route>
     <Route path='/news/aviatrix-review-at-list-casino' element={<New2 news2={news2}/>}></Route>
     <Route path='/news/aviatrix-at-brazilian-igaming-summit' element={<New3 news3={news3}/>}></Route>
     <Route path='/news/heading-to-b2b-egr-awards' element={<New4 news4={news4}/>}></Route>
     <Route path='/news/get-cash-before-you-crash-tournament' element={<New5 news5={news5}/>}></Route>
     <Route path='/news/3rd-day-at-g2e-in-singapore' element={<New6 news6={news6}/>}></Route>
     <Route path='/news/aviatrix-at-g2e-in-asia' element={<New7 news7={news7}/>}></Route>
     <Route path='/news/aviatrix-at-g2e-in-singapore-day-1' element={<New8 news8={news8}/>}></Route>
     <Route path='/news/our-team-in-singapore' element={<New9 news9={news9}/>}></Route>
     <Route path='/news/final-day-of-casinobeats-game-developers-awards' element={<New10 news10={news10}/>}></Route>
     <Route path='/news/nft-project-of-the-year-nomination' element={<New11 news11={news11}/>}></Route>
     <Route path='/news/sbc-summit-north-america-roundup' element={<New12 news12={news12}/>}></Route>
     <Route path='/news/aviatrix-shortlisted-at-egr-marketing-innovation-awards-2023' element={<New13 news13={news13}/>}></Route>
     <Route path='/news/aviatrix-is-teaming-up-with-slotpesa-casino' element={<New14 news14={news14}/>}></Route>
     <Route path='/news/day-1-at-sbc-summit-north-america' element={<New15 news15={news15}/>}></Route>
     <Route path='/news/igathering-event-sponsorship' element={<New16 news16={news16}/>}></Route>
     <Route path='/news/aviatrix-shortlisted-for-game-innovation-spotlight-award' element={<New17 news17={news17}/>}></Route>
     <Route path='/news/schedule-of-events-for-2023' element={<New18 news18={news18}/>}></Route>
     <Route path='/news/interview-with-gamblerspick' element={<New19 news19={news19}/>}></Route>
     <Route path='/news/entering-into-the-new-year-with-the-eu100-000-tournament-from-aviatrix' element={<New20 news20={news20}/>}></Route>
     <Route path='/news/we-have-integrated-aviatrix-to-mostbet'element={<New21 news21={news21}/>}></Route>
     <Route path='/news/merry-christmas' element={<New22 news22={news22}/>}></Route>
     <Route path='/news/new-partner-gamingtec' element={<New23 news23={news23}/>}></Route>
     <Route path='/news/aviatrix-at-the-international-gaming-awards' element={<New24 news24={news24}/>}></Route>
     <Route path='/news/new-operator-vbet' element={<New25 news25={news25}/>}></Route>
     <Route path='/news/eu25-000-new-years-tournament-in-aviatrix-i' element={<New26 news26={news26}/>}></Route>
     <Route path='/news/favbet-one-more-casino-to-play-aviatrix-at' element={<New27 news27={news27}/>}></Route>
     <Route path='/news/interview-with-crypto-gambling-news' element={<New28 news28={news28}/>}></Route>
     <Route path='/news/live-at-parimatch' element={<New29 news29={news29}/>}></Route>
     <Route path='/news/first-ever-aviatrix-network-tournament-is-live' element={<New30 news30={news30}/>}></Route>
     <Route path='/news/congratulations-to-the-winner' element={<New31 news31={news31}/>}></Route>
     <Route path='/news/new-collaboration-with-leon-bet' element={<New32 news32={news32}/>}></Route>
     <Route path='/news/sigma-worlds-gaming-festival-wrap-up' element={<New33 news33={news33}/>}></Route>
     <Route path='/news/1st-day-at-sigma-worlds-gaming-festival-in-malta' element={<New34 news34={news34}/>}></Route>
     <Route path='/news/we-are-planning-to-visit-sigma-worlds-gaming-festival-in-malta' element={<New35 news35={news35}/>}></Route>
     <Route path='/news/an-exciting-and-promising-cooperation' element={<New36 news36={news36}/>}></Route>
     <Route path='/news/europeangaming-on-aviatrix' element={<New37 news37={news37}/>}></Route>
     <Route path='/news/vote-for-aviatrix' element={<New38 news38={news38}/>}></Route>
     <Route path='/news/collaboration-with-everymatrix' element={<New39 news39={news39}/>}></Route>
     <Route path='/news/the-release-of-aviatrix' element={<New40 news40={news40}/>}></Route>
     <Route path='/news/aviatrix-partners-with-betconstruct' element={<New41 news41={news41}/>}></Route>
     <Route path='/news/game-offering-with-trendy-nft-based-content' element={<New42 news42={news42}/>}></Route>
     <Route path="*" element={ <PageNotFound />} />
    </Routes>
    <Popup active={modalActive} setActive={setModalActive}/> 
    </div>
  );
}



export default App;
