import './Footer.css';
import footer from  '../../images/footer.gif';
import footer1 from  '../../images/footer1.png';
import footer2 from  '../../images/footer2.png';
import footer3 from  '../../images/footer3.png';
import {motion} from 'framer-motion';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function Footer({setActive}) {
    return (
        <footer className='footer'>
            <div className='footer__copyright'>© 2023 Aviatrix. Все права защищены.</div>
            <img className='footer__clouds' src={footer2} alt='облака' loading='lazy'></img>
            <div id="Branding3" className='footer__container'>
                <motion.div 
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.2, onse: true}}
                className='footer__row'>
                    <motion.h2  variants={animation} className='footer__title' viewport={{ once: true }}>Уникальный Операторский Брендинг <br></br>Игровых Компонентов</motion.h2>
                    <motion.button viewport={{ once: true }} variants={animation} className='footer__button' onClick={() => setActive(true)}>Связаться</motion.button>
                </motion.div>
                <img className='footer__plane' src={footer} alt='самолет' loading='lazy'></img>
                <img className='footer__img' src={footer1} alt='фон' loading='lazy'></img>
                <img className='footer__img2' src={footer3} alt='фон' loading='lazy'></img>
            </div>
        </footer>
    )
}

export default Footer;