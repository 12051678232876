import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New3({news3}) {
    useEffect(() => {
        document.title = news3.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Следующая остановка для команды Aviatrix — SiGMA World Americas & Brasilian iGaming Summit в оживленном городе Сан-Паулу!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news3.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news3.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news3.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Следующая остановка для команды Aviatrix — SiGMA World Americas & Brasilian iGaming Summit в оживленном городе Сан-Паулу!</p>
            <p className='new__text'>Мы готовимся наслаждаться солнечными днями, демонстрируя Aviatrix, нашу отмеченную наградами краш-игру, которая, несомненно, вас поразит.</p>
            <p className='new__text'>Так что отмечайте в своих календарях, потому что мы будем ждать встречи со всеми вами на стенде 📍 C120.</p>
            <p className='new__text'>Кстати, кто-нибудь умеет танцевать самбу? Нам определенно нужно освежить свои движения, прежде чем мы отправимся на танцпол в Бразилии 🇧🇷.</p>
            <p className='new__text'>До скорой встречи, амигос 🌴!</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New3;
