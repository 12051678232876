import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New29({news29}) {
    useEffect(() => {
        document.title = news29.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'С большим удовольствием сообщаем, что Авиатрикс сегодня в прямом эфире на Parimatch. Еще один оператор, предоставляющий доступ к нашей игре - еще больше счастливых любителей краш-игр.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news29.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news29.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news29.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>С большим удовольствием сообщаем, что Авиатрикс сегодня в прямом эфире на <a target="_blank" className='new__link' href='https://www.linkedin.com/company/parimatch-tech1/'>Parimatch</a>. 🤩 Еще один оператор, предоставляющий доступ к нашей игре - еще больше счастливых любителей краш-игр.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New29;

