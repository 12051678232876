import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New9({news9}) {
    useEffect(() => {
        document.title = news9.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Команда Aviatrix уже на G2E Asia! Aviatrix готова взорвать сцену iGaming, и нам не терпится начать это эпическое путешествие.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news9.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news9.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news9.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Команда Aviatrix уже на G2E Asia!</p>
            <div className='new__column'>
            <p className='new__text'>Мы очень рады видеть всех завтра на G2E Asia!</p>
            <p className='new__text'>Aviatrix готова взорвать сцену iGaming, и нам не терпится начать это эпическое путешествие.</p>
            <p className='new__text'>Познакомьтесь с нашей командой:</p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4'>Николай Побал</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAABWup5UBwVARjHVbX0RJn_ayLudOHyNwfFs'>Анастасия Римская</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAAAwUI-oBiDaKIzigMtVvwGNfpw4mcz7FCyg'>Люси Кадлецова</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAABPpduUBIOXToBnwtshUf6xXuS2GGwa11Ms'>Анаит Петросян</a></p>
            <p className='new__text'>⭐️ <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAABOQwJUBGcqIxaGyMGPEzv4LmBGFWXrhptA'> Андрей Филипович</a></p>
            <p className='new__text'><a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAABOQwJUBGcqIxaGyMGPEzv4LmBGFWXrhptA'>‍Встречайте</a> нас на 📍 стенде B1805, и давайте погрузимся в уникальные особенности отмеченной наградами краш-игры Aviatrix!</p>
            <p className='new__text'>Приготовьтесь к инновациям, азарту и незабываемым впечатлениям.</p>
            <p className='new__text'>Присоединяйтесь к нам, познакомьтесь с нашей командой и попробуйте Aviatrix!</p>
            </div>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New9;

