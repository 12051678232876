import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New13({news13}) {
    useEffect(() => {
        document.title = news13.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Aviatrix вылетает в шорт-листы EGR Marketing & Innovation Awards 2023! Это сезон наград для Aviatrix, и волнение витает сквозь облака!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news13.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news13.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news13.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Aviatrix вылетает в шорт-листы EGR Marketing & Innovation Awards 2023!</p>
            <p className='new__text'>Это сезон наград для Aviatrix, и волнение витает сквозь облака!</p>
            <p className='new__text'>Мы рады сообщить, что Aviatrix вошла в шорт-лист двух категорий премии EGR Marketing & Innovation Awards!</p>
            <p className='new__text'>🏆 Маркетинговая кампания B2B</p>
            <p className='new__text'>🏆 Маркетинговая кампания поставщиков</p>
            <p className='new__text'>Это свидетельство непоколебимой приверженности и творчества нашей маркетинговой команды, которая играет неотъемлемую роль в развитии и успехе Aviatrix.</p>
            <p className='new__text'>Мы невероятно гордимся тем, что нас признали одними из лучших в отрасли.🚀</p>
            <p className='new__text'>Вместе мы продолжим раздвигать границы, переосмысливать краш-игры и вдохновлять мир.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New13;

