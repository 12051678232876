import './Summit.css';
import {motion} from 'framer-motion';
import summit1 from '../../images/summit1.svg';
import summit2 from '../../images/summit2.svg';
import summit3 from '../../images/summit3.svg';
import summit4 from '../../images/summit4.png';
import summit5 from '../../images/summit5.svg';
import summit6 from '../../images/summit6.svg';
import summitback from '../../images/summitback.svg';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,     
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}
function Summit() {
    return (
        <motion.section 
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        className='summit' variants={animation}>
            <div className='summit__cards'>
            <div className='summit__card'>
                <img src={summit6} className='summit__frame' loading="lazy" alt='рамка'></img>
                <div className='summit__container'>
                <img src={summit1} className='summit__img' loading="lazy" alt='Binance'></img>
                <img src={summitback} className='summit__image' loading="lazy" alt='фон'></img>
                <div className='summit__title'>Умная сеть Binance</div>
                <p className='summit__subtitle'>Победители хакатона</p>
                </div>
                <img src={summit5} className='summit__frame' loading="lazy" alt='рамка'></img>
            </div>
            <div className='summit__card'>
                <img src={summit6} className='summit__frame' loading="lazy" alt='рамка'></img>
                <div className='summit__container'>
                <img src={summit2} className='summit__img' loading="lazy" alt='саммит'></img>
                <img src={summitback} className='summit__image' loading="lazy" alt='фон'></img>
                <div className='summit__title'>Sigma Balkans & СНГ</div>
                <p className='summit__subtitle'>Единственная торговая точка</p>
                </div>
                <img src={summit5} className='summit__frame' loading="lazy" alt='рамка'></img>
            </div>
            </div>
            <div className='summit__cards'>
            <div className='summit__card'>
                <img src={summit6} className='summit__frame' loading="lazy" alt='саммит'></img>
                <div className='summit__container'>
                <img src={summit3} className='summit__img' loading="lazy" alt='рамка'></img>
                <img src={summitback} className='summit__image' loading="lazy" alt='фон'></img>
                <div className='summit__title'>SPICE Индия 2023</div>
                <p className='summit__subtitle'>Развивающийся стартап</p>
                </div>
                <img src={summit5} className='summit__frame' loading="lazy" alt='рамка'></img>
            </div>
            <div className='summit__card'>
                <img src={summit6} className='summit__frame' loading="lazy" alt='рамка'></img>
                <div className='summit__container'>
                <img src={summit4} className='summit__img' loading="lazy" alt='саммит'></img>
                <img src={summitback} className='summit__image' loading="lazy" alt='фон'></img>
                <div className='summit__title'>AIBC Евразия</div>
                <p className='summit__subtitle'>Сообщество NFT</p>
                </div>
                <img src={summit5} className='summit__frame' loading="lazy" alt='рамка'></img>
            </div>
            </div>
        </motion.section>
    )
}

export default Summit;
