import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New11({news11}) {
    useEffect(() => {
        document.title = news11.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Ажиотаж вокруг Aviatrix продолжает расти, поскольку мы получили еще один повод безмерно гордиться своей работой. Aviatrix вошла в шорт-лист SiGMA Awards Americas 2023 в номинации NFT Project of the Year!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news11.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news11.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news11.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Ажиотаж вокруг Aviatrix продолжает расти, поскольку мы получили еще один повод безмерно гордиться своей работой. Aviatrix вошла в шорт-лист SiGMA Awards Americas 2023 в номинации NFT Project of the Year!</p>
            <p className='new__text'>Нам нужна ваша поддержка, чтобы идти к победе. Ваш голос может сыграть решающую роль в том, чтобы помочь Авиатрикс получить награду. Итак, пусть ваш голос будет услышан, и заходите на ⏬: <a target="_blank" className='new__link' href='https://sigma.world/americas/awards/vote/'>https://sigma.world/americas/awards/vote/</a></p>
            <p className='new__text'>Мы безмерно благодарны за вашу поддержку и за то, что вы являетесь частью нашего сообщества Aviatrix.</p>
            <p className='new__text'>Вместе мы продолжим парить над облаками. PS Помните, голос за Авиатрикс — это голос за бесконечные приключения и безграничное веселье!</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New11;

