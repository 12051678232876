import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New6({news6}) {
    useEffect(() => {
        document.title = news6.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Хотим выразить благодарность всем, кто встретился с нами и проявил интерес к нашей краш-игре Авиатрикс. Одно привело к другому, и наша суперкоманда рада скорой встрече в Бразилии!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news6.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news6.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news6.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Вот и подошел к концу последний день в Сингапуре на G2E Asia!</p>   
            <p className='new__text'>Последние несколько дней были невероятно продуктивными и насыщенными.</p>   
            <p className='new__text'>Хотим выразить благодарность всем, кто встретился с нами и проявил интерес к нашей краш-игре Авиатрикс.</p>   
            <p className='new__text'>Одно привело к другому, и наша суперкоманда рада скорой встрече в Бразилии!</p>   
            <p className='new__text'>P.S. Кажется, мы отправляемся в кругосветное путешествие, не так ли?</p>   
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New6

