import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New41({news41}) {
    useEffect(() => {
        document.title = news41.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Авиатрикс сотрудничает с BetConstruct. Мы с гордостью сообщаем, что Aviatrix недавно стала партнером BetConstruct, одного из ведущих разработчиков программного обеспечения в сфере IGaming.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news41.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news41.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news41.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Авиатрикс сотрудничает с <a target="_blank" className='new__link' href='https://www.linkedin.com/company/betconstruct/'>BetConstruct</a> 🤗</p>
            <p className='new__text'>Мы с гордостью сообщаем, что <a target="_blank" className='new__link' href='https://www.linkedin.com/company/aviatrix-bet/'>Aviatrix</a> недавно стала партнером <a target="_blank" className='new__link' href='https://www.linkedin.com/company/betconstruct/'>BetConstruct</a> , одного из ведущих разработчиков программного обеспечения в сфере IGaming 🔥. Мы уверены, что наше новое сотрудничество принесет большую пользу как <a target="_blank" className='new__link' href='https://www.linkedin.com/company/aviatrix-bet/'>Aviatrix</a>🚀, так и <a target="_blank" className='new__link' href='https://www.linkedin.com/company/betconstruct/'>BetConstruct</a>, а игроки 🎰 получат еще больше возможностей сыграть в новую краш-игру с необычной механикой и программой лояльности.</p>
            <p className='new__text'>Если вы заинтересованы в сотрудничестве с нами, пожалуйста, свяжитесь с Николаем Побалем. 📅 Если вы хотите попробовать нашу новую игру, посетите <a target="_blank" className='new__link' href='http://aviatrix.bet/'>http://aviatrix.bet/</a>.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New41;

