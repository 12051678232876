import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New17({news17}) {
    useEffect(() => {
        document.title = news17.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Авиатрикс мчится к награде CasinoBeats Game Developer Awards 2023! Авиатрикс мчится к награде CasinoBeats Game Developer Awards 2023!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news17.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news17.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news17.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Вы видите этот след от самолета в небе?</p>
            <p className='new__text'>Авиатрикс мчится к награде CasinoBeats Game Developer Awards 2023!</p>
            <p className='new__text'>Мы рады сообщить, что Aviatrix вошла в шорт-лист премии Game Innovation Spotlight Award!</p>
            <p className='new__text'>Наша команда на седьмом небе от счастья, а наши пилоты крутят колеса в воздухе.</p>
            <p className='new__text'>Мы готовимся к награде и не можем дождаться, чтобы узнать результат.</p>
            <p className='new__text'>Наш шкаф с трофеями пополняется, и у нас всегда есть место для еще одного (или более одного - мы не привередливые!) 😉</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New17;

