import './Popup.css';
import close from '../../images/close.svg';
import popup from '../../images/popup.png';
import popup2 from '../../images/popup2.png';

function Popup({active, setActive}) {
    return (
        <div className={active ? 'popup active' : 'popup'}>
            <div className='popup__container'>
                <img className='popup__close' src={close} alt='крестик' loading='lazy' onClick={() => setActive(false)}></img>
                <div className='popup__column'>
                    <img className='popup__img' src={popup} alt='сообщение' loading='lazy'></img>
                     <img className='popup__img_mobile' src={popup2} alt='сообщение' loading='lazy'></img>
                </div>
                <div className='popup__column'>
                    <form className='popup__form'>
                        <div className='popup__title'>Связаться</div>
                        <label className='popup__label'>Email</label>
                        <input className='popup__input' placeholder='Введите ваш email'></input>
                        <div className='popup__labels'>
                            <label className='popup__head'>Сообщение</label>
                            <label className='popup__head'>(Необязательно)</label>
                        </div>
                        <textarea placeholder='Введите ваше сообщение' className='popup__text'></textarea>
                        <button className='popup__button' placeholder='Отправить'>Отправить</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Popup;
