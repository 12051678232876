import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New2({news2}) {
    useEffect(() => {
        document.title = news2.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Aviatrix представила краш-игру, которая вращается вокруг персонализированных самолетов, с серьезными новшествами в настройке и возможностью соревноваться с другими игроками за дополнительные призовые деньги.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);
    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news2.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news2.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news2.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>Aviatrix представила краш-игру, которая вращается вокруг персонализированных самолетов, с серьезными новшествами в настройке и возможностью соревноваться с другими игроками за дополнительные призовые деньги.</p>
            <p className='new__text'>Механика игры проста: игроки выбирают сумму ставки перед взлетом и стремятся посадить самолет до того, как он взорвется, чтобы выиграть накопленный множитель в момент приземления.</p>
            <p className='new__text'>Aviatrix предлагает среднюю волатильность, а ее возврат игроку (RTP) 97% выше среднего. Игра доказуемо честна, что дает игрокам возможность свободно проверять генератор случайных чисел (ГСЧ), а также персонализировать и открывать новые модели самолетов, зарабатывая очки опыта (XP) и участвуя в турнирах.</p>
            <p className='new__text'>Кажется, что Aviatrix — это игра на реальные деньги, которая впечатлила игроков сочетанием классического дизайна самолетов, настраиваемых самолетов и возможностью выиграть денежные призы. Игра также добавила геймифицированный слой в игровой процесс, позволяя игрокам зарабатывать и настраивать свои самолеты, создавая чувство собственности и вложения в игру.</p>
            <p className='new__text'>Внимание к деталям в дизайне самолетов, вдохновленное реальными самолетами времен Первой и Второй мировых войн и развлекательной авиацией, придает игре дополнительный уровень аутентичности и ностальгии. создали возможности для соревновательных мероприятий и кампаний, добавив элемент волнения и духа товарищества среди игроков.</p>
            <p className='new__text'>Запланированная торговая площадка, где игроки смогут торговать самолетами и их частями в будущем, следуя тенденции владения NFT, также является уникальной особенностью, демонстрирующей способность студии мыслить нестандартно. В целом, как новичок, Aviatrix сделала замечательный вход. в мир игр на реальные деньги, предлагая баланс между простотой и азартом, который привлекает внимание даже самых взыскательных игроков.</p>
            <p className='new__text'>Чтобы прочитать всю статью, пожалуйста,<a target="_blank" href='https://gamblescope.com/slots/aviatrix-crash/' className='new__link'>перейдите по ссылке.</a></p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New2;