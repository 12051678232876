import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New40({news40}) {
    useEffect(() => {
        document.title = news40.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'В этот великий день выходит наша долгожданная и уникальная игра Авиатрикс. Компания работала над разработкой игры круглый год, и теперь мы можем сказать, что мы наконец-то вышли в эфир!';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news40.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news40.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news40.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>🎆 В этот великий день выходит наша долгожданная и уникальная игра Авиатрикс. Компания работала над разработкой игры круглый год, и теперь мы можем сказать, что мы наконец-то вышли в эфир!</p>
            <p className='new__text'>🚀 Aviatrix — это новейшее B2B-решение в сфере онлайн-игр. Помимо обычной игры в краш-игры, у игроков есть возможность создавать свои собственные предметы NFT в форме самолета. ✈ Самолеты можно кастомизировать: они могут быть разных моделей и цветов. И в будущем они также будут продаваться на рынке. 💰В игре беспрецедентная программа лояльности - игроки могут принимать участие в турнирах каждый день!</p>
            <p className='new__text'>Наш первый партнер, подключенный к Aviatrix, — PIN-UP CASINO 🆙. Мы хотели бы поздравить эту игровую онлайн-платформу с этим счастливым событием. Если вы также хотите подключиться к нашей игре, пожалуйста, свяжитесь с <a target="_blank" className='new__link' href=''>Николаем Побалом</a> . 👨💼</p>
            <p className='new__text'>Чтобы попробовать сыграть в игру и узнать больше, перейдите по ссылке <a target="_blank" className='new__link' href='http://aviatrix.bet/'>http://aviatrix.bet/</a> .</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New40;

