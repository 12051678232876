import './Clients.css';
import React, { useEffect } from 'react';
import partners1 from '../../images/partners1.png'; 
import partners2 from '../../images/partners2.png'; 
import partners3 from '../../images/partners3.png'; 
import partners4 from '../../images/partners4.png'; 
import partners5 from '../../images/partners5.png'; 
import partners6 from '../../images/partners6.png'; 
import partners7 from '../../images/partners7.png'; 
import partners8 from '../../images/partners8.png'; 
import partners9 from '../../images/partners9.png'; 
import partners10 from '../../images/partners10.png'; 
import partners11 from '../../images/partners11.png'; 
import partners12 from '../../images/partners12.png'; 
import partners13 from '../../images/partners13.png'; 
import partners14 from '../../images/partners14.png'; 
import partners15 from '../../images/partners15.png'; 
import partners16 from '../../images/partners16.png'; 
import partners17 from '../../images/partners17.png'; 
import partners18 from '../../images/partners18.svg'; 
import partners19 from '../../images/partners19.svg'; 
import partners20 from '../../images/partners20.png'; 
import partners21 from '../../images/partners21.svg'; 
import partners22 from '../../images/partners1.3.png'; 
import partners23 from '../../images/partners1.6.png'; 
import partners24 from '../../images/partners22.svg'; 
import partners25 from '../../images/partners23.svg'; 
import partners26 from '../../images/partners24.svg'; 
import partners27 from '../../images/partners25.svg'; 
import partners28 from '../../images/partners26.svg'; 
import partners29 from '../../images/partners27.svg'; 
import partners30 from '../../images/partners28.svg'; 
import partners31 from '../../images/partners29.svg'; 
import partners32 from '../../images/partners30.svg'; 
import partners33 from '../../images/partners31.svg'; 
import partners34 from '../../images/partners32.svg'; 
import partners35 from '../../images/partners33.svg'; 
import partners36 from '../../images/partners34.svg'; 
import partners37 from '../../images/partners35.png'; 
import partners38 from '../../images/partners36.png'; 
import partners39 from '../../images/partners37.png'; 
import partners40 from '../../images/partners38.svg'; 
import partners41 from '../../images/partners39.svg'; 
import partners42 from '../../images/partners40.svg'; 
import partners43 from '../../images/partners41.svg'; 
import partners44 from '../../images/partners42.svg'; 
import partners45 from '../../images/partners43.png'; 
import partners46 from '../../images/partners44.png'; 
import partners47 from '../../images/partners45.png'; 
import partners48 from '../../images/partners46.png'; 
import partners49 from '../../images/partners47.png'; 

import moon from '../../images/news.png';
import meteor from '../../images/meteor.png';
import {motion} from 'framer-motion';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,  
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }      
    }
}

function Clients() {
    useEffect(() => {
        document.title = 'Партнеры';
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Наши партнеры…';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);
    return (
        <motion.section 
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
        className='clients'>
            <motion.h2 variants={animation} className='clients__title'>Партнеры</motion.h2>
            <motion.div variants={animation} className='clients__container'>
                    <img className='clients__img' src={partners1} alt="gamingtec" loading="lazy"></img>
                    <img className='clients__img' src={partners2} alt="binance" loading="lazy"></img>
                    <a target="_blank" href='/' className='clients__img-link'>
                    <img className='clients__img' src={partners22} alt="pin-up" loading="lazy"></img>
                    </a>
                    <img className='clients__img' src={partners3} alt="scorum" loading="lazy"></img>
                    <img className='clients__img' src={partners4} alt="zing" loading="lazy"></img>
                    <img className='clients__img' src={partners5} alt="favbet" loading="lazy"></img>
                    <a target="_blank"href="https://casinomentor.com/insider/interview-with-aviatrix-an-exciting-and-promising-cooperation-338" className='clients__img-link'>
                    <img className='clients__img' src={partners23} alt="mentor" loading="lazy"></img>
                    </a>
                    <img className='clients__img' src={partners6} alt="pari match" loading="lazy"></img>
                    <img className='clients__img' src={partners7} alt="vbet" loading="lazy"></img>
                    <img className='clients__img' src={partners8} alt="betconstract" loading="lazy"></img>
                    <img className='clients__img' src={partners9} alt="every matrix" loading="lazy"></img>
                    <img className='clients__img' src={partners10} alt="soft bet" loading="lazy"></img>
                    <img className='clients__img' src={partners11} alt="infingame" loading="lazy"></img>
                    <a target="_blank" href='/' className='clients__img-link'>
                    <img className='clients__img' src={partners12} alt="leon" loading="lazy"></img>
                    </a>
                    <a target="_blank" href="https://slotcatalog.com/en/slots/Aviatrix" className='clients__img-link'>
                    <img className='clients__img' src={partners13} alt="slot catalog" loading="lazy"></img>
                    </a>
                    <a target="_blank" href="https://www.slots-777.com/games/slot/aviatrix/aviatrix" className='clients__img-link'>
                    <img className='clients__img' src={partners14} alt="slot-777" loading="lazy"></img>
                    </a>
                    <a target="_blank" href="https://www.machineslotonline.it/slot/aviatrix/aviatrix" className='clients__img-link'>
                    <img className='clients__img' src={partners15} alt="machine slotonline" loading="lazy"></img>
                    </a>
                    <img className='clients__img' src={partners16} alt="quantum gaming" loading="lazy"></img>
                    <img className='clients__img' src={partners17} alt="st8" loading="lazy"></img>
                    <a target="_blank" href="https://bojoko.com/" className='clients__img-link'>
                    <img className='clients__img' src={partners18} alt="bojoko" loading="lazy"></img>
                    </a>
                    <img className='clients__img' src={partners19} alt="mostbet" loading="lazy"></img>
                    <img className='clients__img' src={partners20} alt="slot calendar" loading="lazy"></img>
                    <img className='clients__img' src={partners21} alt="betboom" loading="lazy"></img>
                    <img className='clients__img' src={partners24} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners25} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners26} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners27} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners28} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners29} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners30} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners31} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners32} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners33} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners34} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners35} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners36} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners37} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners38} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners39} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners40} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners41} alt="партнер" loading="lazy"></img>
                    <img className='clients__img' src={partners42} alt="партнер" loading="lazy"></img>
                    <a target="_blank" className='clients__img-link' href='https://www.gamingreport.it/notizie/aviatrix-crash-game-salto-evolutivo'>
                    <img className='clients__img' src={partners43} alt="партнер" loading="lazy"></img>
                    </a>
                    <a target="_blank" className='clients__img-link' href='https://www.giochidislots.com/it/blog/aviatrix-crash-game-funzione-nft'>
                    <img className='clients__img' src={partners44} alt="партнер" loading="lazy"></img>
                    </a>
                    <a target="_blank" className='clients__img-link' href='https://www.tragaperrasweb.es/blog/experiencia-en-altura-con-aviatrix-el-nuevo-crash-game-con-funcion-nft'>
                    <img className='clients__img' src={partners45} alt="партнер" loading="lazy"></img>
                    </a>
                    <a target="_blank" className='clients__img-link' href='https://www.videoslotonline.it/slot-arcade/aviatrix'>
                    <img className='clients__img' src={partners46} alt="партнер" loading="lazy"></img>
                    </a>
                    <a target="_blank" className='clients__img-link' href='https://list.casino/free-slots/aviatrix/'>
                    <img className='clients__img' src={partners47} alt="партнер" loading="lazy"></img>
                    </a>
                    <a target="_blank" className='clients__img-link' href='https://nettikasinolista.com/rahapelit/aviatrix/'>
                    <img className='clients__img' src={partners48} alt="партнер" loading="lazy"></img>
                    </a>
                    <img className='clients__img' src={partners49} alt="партнер" loading="lazy"></img>
            </motion.div>
                <img className='clients__moon' src={moon} alt='луна'></img>
                <img className='clients__meteor' src={meteor} alt='метеорит'></img>
        </motion.section>
    )
}

export default Clients;
