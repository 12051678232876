import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New36({news36}) {
    useEffect(() => {
        document.title = news36.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'На этой неделе у нас есть возможность пообщаться с Владиславом Артемьевым — генеральным директором Aviatrix. Поделятся своими лучшими идеями в 2022 году и некоторыми интересными подробностями о быстро развивающемся 2023 году.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news36.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news36.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news36.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'>На этой неделе у нас есть возможность пообщаться с Владиславом Артемьевым — генеральным директором Aviatrix. Поделятся своими лучшими идеями в 2022 году и некоторыми интересными подробностями о быстро развивающемся 2023 году.</p>
            <p className='new__text'>Все интервью <a target="_blank" className='new__link' href='https://casinomentor.com/insider/interview-with-aviatrix-337'>здесь</a></p>
            <div className='new__name'>Вопрос 1: Пожалуйста, расскажите о себе, а также о Aviatrix, ее ценности и миссии для будущих игроков.</div>
            <p className='new__text'>Привет, меня зовут Владислав Артемьев, я генеральный директор компании Aviatrix, которая разработала одноименную онлайн-краш-игру NFT с отличной программой лояльности, возможностями настройки и возможностью наблюдать за другими пользователями, играющими в ту же игру в фоновом режиме. Когда мы приступили к разработке игры, мы решили, что должны попытаться изменить всю индустрию онлайн-игр и создать что-то новое, игру, которую онлайн-геймеры еще не видели. Таким образом мы. представила понятие NFT сообществу онлайн-игр и представила множество других интересных функций, которые понравятся игрокам.</p>
            <div className='new__name'>Вопрос 2: Сколько игр вы уже выпустили?</div>
            <p className='new__text'>Пока мы фокусируемся только на одной игре, однако это не обычная игра в казино, так как их много. Мы постарались создать что-то эксклюзивное и даже можем похвастаться Наградой, которую мы недавно получили на Sigma Balkans Expo под названием «Уникальное торговое предложение».</p>
            <p className='new__text'>В этой игре мы объединили самые современные технологии, такие как блокчейн, NFT и AI, в одном продукте. Сочетание всех этих технологий и то, как они дополняют друг друга, позволило нам создать решение, которое имеет большой потенциал для изменения стандартов индустрии iGaming.</p>
            <p className='new__text'>Кроме того, пока это не окончательное решение, мы планируем постепенно добавлять в игру новые интересные функции.</p>
            <div className='new__name'>Вопрос 3: Можете ли вы рассказать нам больше о том, как Авиатрикс работает ежедневно? Как выглядит обычный день в вашем офисе?</div>
            <p className='new__text'>С приходом Covid-19 у нас в офисе произошла большая трансформация, и постепенно все сотрудники стали работать из дома. И на самом деле, мы так и не вернулись в офис. Сейчас все наши коллеги разбросаны по разным странам, и каждый работает в своем домашнем офисе. Так что, по сути, ваш рабочий день может начинаться либо в 8 часов, когда дети идут в школу, либо в 10 часов, если вы любите поспать допоздна.</p>
            <div className='new__name'>Вопрос 4: На какие категории игр вы ориентируетесь? Есть ли прорывные инновации для этого типа игр?</div>
            <p className='new__text'>Сейчас наша основная цель — краш-игры. В нашей игре у игроков есть возможность выбрать или построить модель самолета, с которой они хотят играть, а также настроить самолет по своему усмотрению. Они также могут наблюдать за другими игроками, летящими на заднем плане, как в том же казино, так и даже в разных. Когда игрок создает самолет в игре, это на самом деле NFT, что означает, что в будущем он сможет обменивать этот самолет с другими игроками или использовать его в других играх. В игре также есть отличная программа лояльности. По сути, чем больше ставок делает геймер, тем более высокие награды они могут получить в будущем. Помимо обычных и обычных выплат, раздача вознаграждений для постоянных игроков происходит каждые 24 часа.</p>
            <div className='new__name'>Вопрос 5: Какие трудности преодолела ваша команда за последние годы?</div>
            <p className='new__text'>Одной из основных проблем, с которыми мы столкнулись в последний год, является создание такого сложного типа игры с блокчейном и NFT. Это совершенно новое явление в онлайн-играх, и было сложно, но очень интересно разрабатывать этот новый вид игр. Мы потратили немного времени на изучение и поиск способов интеграции блокчейна, NFT и различных новых функций, таких как ежедневные турниры, персонализация, мультиплеер и другие.</p>
            <div className='new__name'>Вопрос 6: Какие рынки вы считаете наиболее подходящими для выбора игры?</div>
            <p className='new__text'>Вначале мы сосредоточимся на странах Европы, Латинской Америки и Африки. А через какое-то время мы тоже будем подавать заявку на лицензию США.</p>
            <div className='new__name'>Вопрос 8: Как вы остаетесь в курсе последних тенденций в играх и программном обеспечении?</div>
            <p className='new__text'>Мы посещаем большинство отраслевых выставок, например, прямо сейчас мы планируем посетить выставку Sigma на Мальте. Наши разработчики следят за всеми последними тенденциями в области программного обеспечения на отраслевых веб-сайтах и ​​конференциях.</p>
            <div className='new__name'>Вопрос 9: Кратко поговорим о будущем. В каком направлении вы видите движение Aviatrix в ближайшие годы?</div>
            <p className='new__text'>Мы планируем улучшать нашу текущую игру и постепенно добавлять новые интересные функции. Кроме того, мы собираемся создать совершенно новую Метавселенную с несколькими играми, используя активы, которые уже доступны в Aviatrix. Например, прямо сейчас в нашей игре есть самолеты, которые являются NFT, и в будущем вы сможете играть с этими NFTS в наших новых играх.</p>
            <div className='new__name'>Вопрос 10: Есть ли у вас планы по маркетингу в отношении партнерства с CasinoMentor? Например, что вы ожидаете от этого партнерства?</div>
            <p className='new__text'>Мы будем рады предоставить Casinomentor обновления о нашей игре Aviatrix и любых других играх, которые мы будем разрабатывать в будущем.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New36;

