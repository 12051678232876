import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New25({news25}) {
    useEffect(() => {
        document.title = news25.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Мы рады сообщить, что наша отмеченная наградами краш-игра NFT Aviatrix теперь работает с нашим новым оператором VBET. Все больше и больше людей получают шанс выиграть деньги';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news25.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news25.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news25.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
            <p className='new__text'></p>
            <p className='new__text'>Мы рады сообщить, что наша отмеченная наградами краш-игра NFT Aviatrix теперь работает с нашим новым оператором <a target="_blank" className='new__link' href='https://www.linkedin.com/company/vbet-official/'>VBET</a>. 🤩 Все больше и больше людей получают шанс выиграть деньги, летая на наших самолетах NFT 💸. Если вы хотите интегрировать нашу игру 🎮 в свое казино, пожалуйста, свяжитесь с <a target="_blank" className='new__link' href='https://www.linkedin.com/in/ACoAAB977MABytibGBwAkl_pAfI02wEBok4Epz4'>Николаем Побалом</a>.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New25;

