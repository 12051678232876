import './Benefits.css';
import {motion} from 'framer-motion';
import benefits1 from '../../images/benefits1.png';
import benefits2 from '../../images/benefits2.png';
import benefits3 from '../../images/benefits3.png';
import benefits4 from '../../images/benefits4.png';
import meteor from '../../images/meteor.png';
import planet from '../../images/planet.png';
const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,  
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }   
    }
}
function Benefits() {
    return (
        <section 
        id="Benefits" className='benefits'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.8 }}
            className='benefits__row'>
                <div className='benefits__column'>
                    <motion.div variants={animation} className='benefits__label'>Преимущества</motion.div>
                    <motion.h2 variants={animation} className='benefits__title'>Вывод краш-игр на новый уровень</motion.h2>
                </div>
                <motion.p variants={animation} className='benefits__text'>Богатый набор функций, механика, которая выводит геймификацию, мультиплеер и лояльность на новый уровень.</motion.p>
            </motion.div>
            <div className='benefits__container'>
                <div className='benefits__block'>
                <img className='benefits__photo_min' src={benefits3} alt='игра' loading='lazy'></img>
                    <img className='benefits__img' src={benefits1} alt='игра' loading='lazy'></img>
                </div>
                <div className='benefits__block'>
                    <img className='benefits__image' src={benefits2} alt='игра' loading='lazy'></img>
                </div>
                <div className='benefits__block'>
                    <img className='benefits__photo' src={benefits3} alt='игра' loading='lazy'></img>
                    <img className='benefits__photo_mobile' src={benefits4} alt='игра' loading='lazy'></img>
                </div>
            </div>
            <img className='benefits__meteor' src={meteor} alt='метеорит' loading='lazy'></img>
            <img className='benefits__planet' src={planet} alt='планета' loading='lazy'></img>
        </section>
    )
}

export default Benefits;