import './Partners.css';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import partners1 from '../../images/partners1.png'; 
import partners2 from '../../images/partners2.png'; 
import partners3 from '../../images/partners3.png'; 
import partners4 from '../../images/partners4.png'; 
import partners5 from '../../images/partners5.png'; 
import partners6 from '../../images/partners6.png'; 
import partners7 from '../../images/partners7.png'; 
import partners8 from '../../images/partners8.png'; 
import partners9 from '../../images/partners9.png'; 
import partners10 from '../../images/partners10.png'; 
import partners11 from '../../images/partners11.png'; 
import partners12 from '../../images/partners12.png'; 
import partners13 from '../../images/partners13.png'; 
import partners14 from '../../images/partners14.png'; 
import partners15 from '../../images/partners15.png'; 
import partners16 from '../../images/partners16.png'; 
import partners17 from '../../images/partners17.png'; 
import partners18 from '../../images/partners18.svg'; 
import partners19 from '../../images/partners19.svg'; 
import partners20 from '../../images/partners20.png'; 
import partners21 from '../../images/partners21.svg'; 
import partners22 from '../../images/partners1.3.png'; 
import partners23 from '../../images/partners1.6.png'; 

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,     
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}
function Partners() {
    return (
        <motion.section
        initial='hidden'
        whileInView='visible'
        viewport={{ onse: true}}
        className='partners'>
            <div className='partners__text'>
                <Link to='/clients' className='partners__link'>
                    <div className='partners__all'>См. все 49</div>
                </Link>
                <h2 className='partners__title'>Партнеры</h2>
            </div>
            <motion.div className='partners__overflow' variants={animation}>
                <div className='partners__wrapper'>
                    <img className='partners__img' src={partners1} alt="gamingtec" loading="lazy"></img>
                    <img className='partners__img' src={partners2} alt="binance" loading="lazy"></img>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners22} alt="pin-up" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners3} alt="scorum" loading="lazy"></img>
                    <img className='partners__img' src={partners4} alt="zing" loading="lazy"></img>
                    <img className='partners__img' src={partners5} alt="favbet" loading="lazy"></img>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners23} alt="mentor" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners6} alt="pari match" loading="lazy"></img>
                    <img className='partners__img' src={partners7} alt="vbet" loading="lazy"></img>
                    <img className='partners__img' src={partners8} alt="betconstract" loading="lazy"></img>
                    <img className='partners__img' src={partners9} alt="every matrix" loading="lazy"></img>
                    <img className='partners__img' src={partners10} alt="soft bet" loading="lazy"></img>
                    <img className='partners__img' src={partners11} alt="infingame" loading="lazy"></img>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners12} alt="leon" loading="lazy"></img>
                    </a>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners13} alt="slot catalog" loading="lazy"></img>
                    </a>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners14} alt="slot-777" loading="lazy"></img>
                    </a>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners15} alt="machine slotonline" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners16} alt="quantum gaming" loading="lazy"></img>
                    <img className='partners__img' src={partners17} alt="st8" loading="lazy"></img>
                    <a className='partners__img-link'>
                    <img className='partners__img' src={partners18} alt="bojoko" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners19} alt="mostbet" loading="lazy"></img>
                    <img className='partners__img' src={partners20} alt="slot calendar" loading="lazy"></img>
                    <img className='partners__img' src={partners21} alt="betboom" loading="lazy"></img>
                </div>
                <div className='partners__wrapper'>
                    <img className='partners__img' src={partners1} alt="gamingtec" loading="lazy"></img>
                    <img className='partners__img' src={partners2} alt="binance" loading="lazy"></img>
                    <a href='/' className='partners__img-link'>
                    <img className='partners__img' src={partners22} alt="pin-up" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners3} alt="scorum" loading="lazy"></img>
                    <img className='partners__img' src={partners4} alt="zing" loading="lazy"></img>
                    <img className='partners__img' src={partners5} alt="favbet" loading="lazy"></img>
                    <a href="https://casinomentor.com/insider/interview-with-aviatrix-an-exciting-and-promising-cooperation-338" className='partners__img-link'>
                    <img className='partners__img' src={partners23} alt="mentor" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners6} alt="pari match" loading="lazy"></img>
                    <img className='partners__img' src={partners7} alt="vbet" loading="lazy"></img>
                    <img className='partners__img' src={partners8} alt="betconstract" loading="lazy"></img>
                    <img className='partners__img' src={partners9} alt="every matrix" loading="lazy"></img>
                    <img className='partners__img' src={partners10} alt="soft bet" loading="lazy"></img>
                    <img className='partners__img' src={partners11} alt="infingame" loading="lazy"></img>
                    <a href='/' className='partners__img-link'>
                    <img className='partners__img' src={partners12} alt="leon" loading="lazy"></img>
                    </a>
                    <a href="https://slotcatalog.com/en/slots/Aviatrix" className='partners__img-link'>
                    <img className='partners__img' src={partners13} alt="slot catalog" loading="lazy"></img>
                    </a>
                    <a href="https://www.slots-777.com/games/slot/aviatrix/aviatrix" className='partners__img-link'>
                    <img className='partners__img' src={partners14} alt="slot-777" loading="lazy"></img>
                    </a>
                    <a href="https://www.machineslotonline.it/slot/aviatrix/aviatrix" className='partners__img-link'>
                    <img className='partners__img' src={partners15} alt="machine slotonline" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners16} alt="quantum gaming" loading="lazy"></img>
                    <img className='partners__img' src={partners17} alt="st8" loading="lazy"></img>
                    <a href="https://bojoko.com/" className='partners__img-link'>
                    <img className='partners__img' src={partners18} alt="bojoko" loading="lazy"></img>
                    </a>
                    <img className='partners__img' src={partners19} alt="mostbet" loading="lazy"></img>
                    <img className='partners__img' src={partners20} alt="slot calendar" loading="lazy"></img>
                    <img className='partners__img' src={partners21} alt="betboom" loading="lazy"></img>
                </div>
            </motion.div>
        </motion.section>
    )
}

export default Partners;
