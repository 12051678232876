import './Rewards.css';
import {motion} from 'framer-motion';
import rewards from '../../images/rewards.svg';
import rewards1 from '../../images/rewards1.png';
import rewards2 from '../../images/rewards2.png';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }  
    }
}

function Rewards() {
    return (
        <section id="Rewards" className='rewards'>
            <div className='rewards__row'>
                <motion.div 
                initial='hidden'
                whileInView='visible'
                viewport={{ onse: true}}
                className='rewards__column'>
                    <motion.div variants={animation} className='rewards__label'>Уникальная механика лояльности</motion.div>
                    <motion.h2 variants={animation} className='rewards__title'>Больше Рейсов<br></br>Больше Побед!</motion.h2>
                    <motion.p variants={animation} className='rewards__text'>Лояльность вознаграждается игровыми кредитами / деньгами и зависит как от прогресса, так и от уровня активности пользователя.</motion.p>
                    <div className='rewards__list'>
                        <motion.div variants={animation} className='rewards__item'>
                            <img className='rewards__img' src={rewards} alt='галочка' loading='lazy'></img>
                            <p className='rewards__paragraph'>Ежедневные выплаты кэшбэка с использованием механики пула</p>
                        </motion.div>
                        <div variants={animation} className='rewards__item'>
                            <img className='rewards__img' src={rewards} alt='галочка' loading='lazy'></img>
                            <p className='rewards__paragraph'>Лучшая механика удержания на рынке</p>
                        </div>
                    </div>
                </motion.div>
                <div className='rewards__column'>
                    <img className='rewards__plane' src={rewards1} alt='планета' loading='lazy'></img>
                </div>
            </div>
            <img className='rewards__planet' src={rewards2} alt='самолет' loading='lazy'></img>
        </section>
    )
}

export default Rewards;