import '../New/New.css';
import back from '../../images/wrapper.png';
import back2 from '../../images/wrapper2.png';
import {motion} from 'framer-motion';
import React, { useEffect } from 'react';

const animation = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,   
        transition: {
            type: "easy",
            bounce: 0.4,
            duration: 0.8
          }
    }
}

function New28({news28}) {
    useEffect(() => {
        document.title = news28.text;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = 'Вопросы и ответы: AVIATRIX О СОЗДАНИИ АВАРИЙНОЙ ИГРЫ СЛЕДУЮЩЕГО ПОКОЛЕНИЯ. АВАРИЙНЫЕ ИГРЫ НЕДАВНО ВЗЯЛИ ШТОРМ В ИНДУСТРИИ ОНЛАЙН-КАЗИНО, И ОДИН ПОСТАВЩИК ПОВЫШАЕТ СТАВКИ, ИСПОЛЬЗУЯ NFTS ДЛЯ ПОВЫШЕНИЯ ПЕРСОНАЛИЗАЦИИ И ВОВЛЕЧЕННОСТИ. МЕНЕДЖЕР ПО МАРКЕТИНГУ «АВИАТРИКС» ЮЛИЯ БОГАЧЕВА РАССКАЗАЛА НАМ ПОДРОБНЕЕ.';
        }
    
        const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = window.location.href;
    }
  }, []);

    return (
        <div className='new'>
            <motion.div 
            initial='hidden'
            whileInView='visible'
            viewport={{ onse: true}}
            className='new__container'>
            <motion.div variants={animation} className='new__data'>{news28.data}</motion.div>
            <motion.h1 variants={animation}  className='new__title'>{news28.text}</motion.h1>
            <motion.img variants={animation}  className='new__img' src={news28.image} alt='новость' loading='lazy'></motion.img>
            <motion.div variants={animation}  className='new__content'>
             <div className='new__head'>Вопросы и ответы: AVIATRIX О СОЗДАНИИ АВАРИЙНОЙ ИГРЫ СЛЕДУЮЩЕГО ПОКОЛЕНИЯ</div>
             <div className='new__subhead'>АВАРИЙНЫЕ ИГРЫ НЕДАВНО ВЗЯЛИ ШТОРМ В ИНДУСТРИИ ОНЛАЙН-КАЗИНО, И ОДИН ПОСТАВЩИК ПОВЫШАЕТ СТАВКИ, ИСПОЛЬЗУЯ NFTS ДЛЯ ПОВЫШЕНИЯ ПЕРСОНАЛИЗАЦИИ И ВОВЛЕЧЕННОСТИ. МЕНЕДЖЕР ПО МАРКЕТИНГУ «АВИАТРИКС» ЮЛИЯ БОГАЧЕВА РАССКАЗАЛА НАМ ПОДРОБНЕЕ.</div>
             <div className='new__name'>Пожалуйста, не могли бы вы представить Aviatrix нашим читателям?</div>
             <p className='new__text'><a target="_blank" className='new__link' href='https://gamblescope.com/slots/aviatrix-crash/'>Aviatrix</a> — это новая онлайн-игра NFT, которая быстро завоевывает верных поклонников среди игроков онлайн-казино и азартных игр. Краш-игры завоевывают сердца игроков каждый день, и наша версия предлагает больше возможностей, чем другие на рынке, включая самолеты NFT, на которых игроки могут летать. Ни одна другая игра в онлайн-казино в настоящее время не предлагает этого, и мы интегрировали элемент NFT таким образом, чтобы он не влиял на опыт тех, кто не интересуется этим аспектом.</p>
             <div className='new__name'>Популярность краш-игр резко возросла в последние годы. Что Авиатрикс привносит в вертикаль?</div>
             <p className='new__text'>Вы правы, краш-игры становятся очень популярными. Однако мы обнаружили, что многие игры очень похожи и используют одни и те же основные принципы. Мы решили добавить в продукт новые функции, например полную настройку самолета, на который делают ставки игроки. Это означает, что пользователь может построить свою собственную модель самолета NFT, изменив все, от цвета до корпуса и деталей. Они даже могут видеть, как другие пилоты летают на фоне их игры.</p>
             <p className='new__text'>Поскольку они играют более регулярно и зарабатывают очки опыта, это, в свою очередь, открывает новые уровни и модели самолетов. Очки опыта также дают обычным пользователям преимущество в ежедневных турнирах; чтобы пройти квалификацию на определенные турниры, игрокам необходимо делать хотя бы одну ставку в день, и эти турниры дают игрокам возможность выиграть дополнительно.</p>
             <div className='new__name'>Расскажите нам о своем участии и победе в хакатоне Binance Smart Chain?</div>
             <p className='new__text'>В октябре 2021 года, когда наша игра Aviatrix находилась на очень ранней стадии разработки, мы решили проверить наши гипотезы о ее потенциальном успехе на хакатоне Binance Smart Chain. Для нас было важно выяснить, заинтересуется ли такой проект криптосообществом, и мы хотели посмотреть, сможем ли мы найти на мероприятии потенциальных партнеров и игроков.</p>
             <p className='new__text'>Конкурс состоял из двух этапов и 63 участников, так что это было серьезное мероприятие. Первым этапом было публичное голосование, и изначально казалось, что мы не победим. Однако мы прошли ко второму этапу, где профессиональное жюри учитывало не только эмоциональную привлекательность проекта, но и его потенциал для будущего успеха. Aviatrix (тогда известная как Aviator) набрала 188 очков, больше, чем любой другой участник, и это действительно подчеркнуло долгосрочный потенциал развития игры.</p>
             <div className='new__name'>Почему бренды онлайн-казино должны обращать внимание на NFT?</div>
             <p className='new__text'>Очевидно, что NFT оказали огромное влияние за последние пару лет, и мы рассматриваем их как возможность ввести функции в игры онлайн-казино, которые могут привлечь новый тип игроков и лучше вовлечь уже активных. Вот один пример с Aviatrix: игроки могут переносить свои самолеты NFT из одного казино в другое, сохраняя свои очки опыта и преимущества, которые они дают. Речь идет о том, чтобы поставить игрока на первое место и не ограничивать его опыт.</p>
             <div className='new__name'>Чему сектор онлайн-казино может научиться у мира блокчейна?</div>
             <p className='new__text'>Технология Blockchain предоставляет прибыльные возможности для онлайн-казино, и мы сосредоточены на том, чтобы помочь операторам извлечь из них выгоду. Еще одна функция Aviatrix, которую мы сейчас планируем, — это торговая площадка, где игроки могут покупать и продавать свои самолеты за деньги, что дает им возможность заработать на самолетах, которые они потратили на повышение уровня.</p>
             <p className='new__text'>Эта технология открывает множество дверей для брендов онлайн-казино, чтобы предоставить игрокам более личный опыт. Например, если бренд закрывается, тот факт, что наши самолеты подключены к сети, означает, что другие казино могут немедленно приветствовать игроков несуществующего оператора для игры в Aviatrix без потери их прогресса. Самолеты NFT принадлежат игроку, а не казино, и это действительно мощный стимул.</p>
             <div className='new__name'>Что ждет Авиатрикс?</div>
             <p className='new__text'>На данный момент наша игра уже запущена в нескольких онлайн-казино, и мы прилагаем все усилия, чтобы расширить нашу партнерскую сеть, чтобы представить Aviatrix более широкой аудитории. Мы будем полировать нашу игру и добавлять новые функции, в том числе уже упомянутый рынок.</p>
             <p className='new__text'>Кроме того, мы также создаем целую метавселенную, включая несколько игр, используя ресурсы, уже доступные в Aviatrix. Здесь у игроков есть огромный потенциал для использования своих самолетов NFT в других играх. Это только начало того, что возможно.</p>
            </motion.div>
            </motion.div>
            <img className='new__back' src={back} loading='lazy' alt='фон'></img>
            <img className='new__back' src={back2} loading='lazy' alt='фон'></img>
        </div>
    )
}

export default New28;

