import './Main.css';
import Header from '../Header/Header';
import Banner from '../Banner/Banner';
import Summit from '../Summit/Summit';
import Partners from '../Partners/Partners';
import Wrapper from '../Wrapper/Wrapper';
import Features from '../Features/Features';
import Benefits from '../Benefits/Benefits';
import Planes from '../Planes/Planes';
import Rewards from '../Rewards/Rewards';
import News from '../News/News';
import Footer from '../Footer/Footer';

function Main({news1, news2, news3, news4, news5, news6, news7, news8, news9, news10, news11, news12, news13, news14, news15, news16, news17, news18, news19, news20, news21, news22, news23, news24, news25, news26, news27, news28, news29, news30, news31, news32, news33, news34, news35, news36, news37, news38, news39, news40, news41, news42, setActive}) {
    return (
        <div className='main'>
            <Banner />
            <Summit />
            <Partners />
            <Wrapper />
            <Features />
            <Benefits />
            <Planes />
            <Rewards />
            <News news1={news1} news2={news2} news3={news3} news4={news4} news5={news5} news6={news6} news7={news7} news8={news8} news9={news9} news10={news10} news11={news11} news12={news12} news13={news13} news14={news14} news15={news15} news16={news16} news17={news17} news18={news18} news19={news19} news20={news20} news21={news21} news22={news22} news23={news23} news24={news24} news25={news25} news26={news26} news27={news27} news28={news28} news29={news29} news30={news30} news31={news31} news32={news32} news33={news33} news34={news34} news35={news35} news36={news36} news37={news37} news38={news38} news39={news39} news40={news40} news41={news41} news42={news42 }/>
            <Footer setActive={setActive}/>
        </div>
    )
}

export default Main;